import axios from '../../axios-auth';

const routePath = '/messages';

export default {
  async deleteSelectedMessages({ commit }, selectedMessageUUIDs) {
    console.log('selectedMessageUUIDs from dispatched actions: ', selectedMessageUUIDs);
    return axios
      .delete(`${routePath}/bulk-delete`, { data: { messageUUIDs: selectedMessageUUIDs } })
      .then((res) => {
        commit('removeMultipleMessages', res.data.deletedMessageUUIDs);
        return res;
      })
      .catch(() => {
        throw 'Error bulk deleting message';
      });
  },
};
