<script setup>
import { computed } from 'vue';
import { storeKey } from 'vuex';

// Properties
const { userSubscriptionPlan } = defineProps(['userSubscriptionPlan']);
const emit = defineEmits();

// Store
import { useStore } from "vuex";
const store = useStore();

// Computed properties
const professionelPlan = computed(() => store.getters.getProfessionelPlan);
// const enterprisePlan = computed(() => store.getters.getEnterprisePlan);

// Functions
function registerPlan(plan) {
  emit('selected-membership-plan', plan);
}

const starterPlane = {
  name: 'Starter',
  benefit1: 'Meta ads tracking',
  benefit2: 'Content kalender',
  benefit3: 'Markedsførings tidslinje',
  benefit4: '3 Aktive forretnings partnere',
  benefit5: '',
  benefit6: '',
  benefit7: '',
};

</script>

<template>
  <div class="px-4 py-6 md:px-6 lg:px-8">
    <div class="text-900 font-bold text-6xl mb-4 text-center">
      Opgrader plan
    </div>
    <div class="text-500 text-xl mb-4 text-center line-height-4">
      Alle planer kommer med 7 dages returret
    </div>

    <div class="grid">
      <div class="col-12 lg:col-6">
        <div class="p-1 h-full">
          <div class="shadow-2 p-3 h-full flex flex-column surface-card"
            :style="{ 'border-radius': '6px', border: userSubscriptionPlan === 'Starter' ? '1px solid #609af8' : '' }">
            <div class="grid">
              <div class="col">
                <div class="text-900 font-medium text-xl mb-2">{{ starterPlane.name }}</div>
                <div class="text-600">Plan</div>
              </div>
              <div class="col" style="text-align: right;">
                <svg x="0px" y="0px" width="80" height="50" viewBox="0,0,256,240"
                  style="fill:#000000; margin-left: auto; padding-right: 4px;">
                  <g fill="#000000" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                    stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                    font-family="none" font-weight="none" font-size="none" text-anchor="none"
                    style="mix-blend-mode: normal">
                    <g transform="scale(5.40,5.40)">
                      <path
                        d="M8.98828,11.25c-0.19531,0.00311 -0.3817,0.08229 -0.51953,0.2207l-7.99805,8c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.46875,-7.46875l6.93945,6.93945l-7.46875,7.4707c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-7.46875,-7.46875l6.93945,-6.93945l7.4707,7.46875c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-8,-7.99805c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.14309,-0.14367 -0.33826,-0.22329 -0.54102,-0.2207zM9,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM41,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,29.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945z">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900">Gratis</span>
              <span class="ml-2 font-medium text-600"></span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check text-green-500 mr-2"></i>
                <span class="text-900">{{ starterPlane.benefit1 }}</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check text-green-500 mr-2"></i>
                <span class="text-900">{{ starterPlane.benefit2 }}</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check text-green-500 mr-2"></i>
                <span class="text-900">{{ starterPlane.benefit3 }}</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check text-green-500 mr-2"></i>
                <span class="text-900">{{ starterPlane.benefit4 }}</span>
              </li>
            </ul>
            <!-- <Button outlined disabled label="Aktuel plan" class="p-3 w-full"></Button> -->
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-6">
        <div class="p-1 h-full">
          <div class="shadow-2 p-3 h-full flex flex-column surface-card"
            :style="{ 'border-radius': '6px', border: userSubscriptionPlan === 'Professionel' ? '1px solid #609af8' : '' }">
            <div class="grid">
              <div class="col">
                <div class="text-900 font-medium text-xl mb-2">{{ professionelPlan.visualName }}</div>
                <div class="text-600">Plan</div>
              </div>
              <div class="col" style="text-align: right;">
                <svg x="0px" y="0px" width="80" height="50" viewBox="0,0,256,240"
                  style="fill:#000000; margin-left: auto; padding-right: 4px;">
                  <g fill="#3b82f6" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                    stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                    font-family="none" font-weight="none" font-size="none" text-anchor="none"
                    style="mix-blend-mode: normal">
                    <g transform="scale(5.40,5.40)">
                      <path
                        d="M8.98828,11.25c-0.19531,0.00311 -0.3817,0.08229 -0.51953,0.2207l-7.99805,8c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.46875,-7.46875l6.93945,6.93945l-7.46875,7.4707c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-7.46875,-7.46875l6.93945,-6.93945l7.4707,7.46875c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-8,-7.99805c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.14309,-0.14367 -0.33826,-0.22329 -0.54102,-0.2207zM9,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM41,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,29.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945z">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900">{{ professionelPlan.price.amount }} DKK</span>
              <span class="ml-2 font-medium text-600">per måned</span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul v-for="benefit in professionelPlan.benefits" class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check text-green-500 mr-2"></i>
                <span class="text-900">{{ benefit }}</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <Button v-if="userSubscriptionPlan !== 'Professionel'" label="Køb" @click="registerPlan(professionelPlan)"
              class="p-3 w-full"></Button>
            <Button v-if="userSubscriptionPlan === 'Professionel'" outlined disabled label="Aktuel plan"
              class="p-3 w-full"></Button>
          </div>
        </div>
      </div>

      <!-- <div class="col-12 lg:col-4">
        <div class="p-1 h-full">
          <div class="shadow-2 p-3 flex flex-column surface-card"
            :style="{ 'border-radius': '6px', border: userSubscriptionPlan === 'Enterprise' ? '1px solid #609af8' : '' }">
            <div class="grid">
              <div class="col">
                <div class="text-900 font-medium text-xl mb-2">{{ enterprisePlan.visualName }}</div>
                <div class="text-600">{{ enterprisePlan.description }}</div>
              </div>
              <div class="col" style="text-align: right;">
                <svg x="0px" y="0px" width="80" height="50" viewBox="0,0,256,240"
                  style="fill:#000000; margin-left: auto; padding-right: 4px;">
                  <g fill="#6366f1" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt"
                    stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0"
                    font-family="none" font-weight="none" font-size="none" text-anchor="none"
                    style="mix-blend-mode: normal">
                    <g transform="scale(5.40,5.40)">
                      <path
                        d="M8.98828,11.25c-0.19531,0.00311 -0.3817,0.08229 -0.51953,0.2207l-7.99805,8c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.46875,-7.46875l6.93945,6.93945l-7.46875,7.4707c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-7.46875,-7.46875l6.93945,-6.93945l7.4707,7.46875c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-8,-7.99805c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.14309,-0.14367 -0.33826,-0.22329 -0.54102,-0.2207zM9,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM41,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,29.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945z">
                      </path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900">{{ enterprisePlan.price.amount }} DKK</span>
              <span class="ml-2 font-medium text-600">per month</span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul v-for="benefit in enterprisePlan.benefits" class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">{{ benefit }}</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <Button v-if="userSubscriptionPlan !== 'Enterprise'" label="Køb" @click="registerPlan(enterprisePlan)"
              class="p-3 w-full"></Button>
            <Button v-if="userSubscriptionPlan === 'Enterprise'" outlined disabled label="Aktuel plan"
              class="p-3 w-full"></Button>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<style lang="scss" scoped></style>