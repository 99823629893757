<script setup>
import { ref } from 'vue';
import LandingPageLogo from "@/views/public/components/LandingPageLogo.vue";
import { useLocaleMessages } from "@/utils/locales/useLocaleMessages";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

// Initialization
const { locale } = useI18n();

// Router
const router = useRouter();

// Load locales
useLocaleMessages({
  da: () => import("@/locales/da/footerSection.json"),
  en: () => import("@/locales/en/footerSection.json"),
});

// References
const selectedLanguage = ref(locale.value);

const languages = [
  { code: 'da', name: 'Dansk' },
  { code: 'en', name: 'English' }
];

// Function to change the locale
const changeLanguage = (languageCode) => {
  locale.value = languageCode;
  localStorage.setItem('selectedLanguage', languageCode);
};
</script>

<template>
  <div class="col-12 md:col-2 text-center md:text-left">
    <a class="cursor-pointer" href="#">
      <LandingPageLogo />
    </a>
  </div>

  <div class="col-12 md:col-10 lg:col-8">
    <div class="grid text-center md:text-left">
      <div class="col-12 md:col-3 lg:col-3">
        <h4 class="font-medium text-xl line-height-3 mb-3 text-900">{{ $t("chooseLanguage") }}</h4>
        <Dropdown
            v-model="selectedLanguage"
            :options="languages"
            optionLabel="name"
            optionValue="code"
            placeholder="Select a Language"
            class="language-dropdown"
            @change="changeLanguage(selectedLanguage)"
        />
      </div>

      <div class="col-12 md:col-3 lg:col-2">
        <h4 class="font-medium text-xl line-height-3 mb-3 text-900">
          {{ $t("company") }}
        </h4>
        <a class="line-height-3 block cursor-pointer mb-2 text-700">{{
          $t("aboutUs")
        }}</a>
        <a class="line-height-3 block cursor-pointer mb-2 text-700">{{
          $t("contactUs")
        }}</a>
      </div>

      <div class="col-12 md:col-3 mt-4 lg:col-2 md:mt-0">
        <h4 class="font-medium text-xl line-height-3 mb-3 text-900">
          {{ $t("resources") }}
        </h4>
        <a
          @click="router.push('/resourcer')"
          class="footer-button line-height-3 block cursor-pointer mb-2 text-700"
          >{{ $t("ourPosts") }}</a
        >
        <a class="line-height-3 block cursor-pointer text-700">{{
          $t("caseStudies")
        }}</a>
      </div>

      <div class="col-12 md:col-3 lg:col-2 mt-4 md:mt-0">
        <h4 class="font-medium text-xl line-height-3 mb-3 text-900">
          {{ $t("community") }}
        </h4>
        <a class="line-height-3 block cursor-pointer mb-2 text-700">{{
          $t("events")
        }}</a>
      </div>

      <div class="col-12 md:col-3 lg:col-2 mt-4 md:mt-0">
        <h4 class="font-medium text-xl line-height-3 mb-3 text-900">
          {{ $t("legal") }}
        </h4>
        <a
          @click="router.push('/termsandconditions')"
          class="footer-button line-height-3 block cursor-pointer mb-2 text-700"
          >{{ $t("termsAndConditions") }}</a
        >
        <a
          @click="router.push('/privacypolicy')"
          class="footer-button line-height-3 block cursor-pointer mb-2 text-700"
          >{{ $t("privacyPolicy") }}</a
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.footer-button:hover {
  color: var(--primary-600) !important;
}

.language-dropdown {
  width: 10rem; /* You can adjust the size here */
  font-size: 10px; /* Adjust the font size if needed */
}
</style>
