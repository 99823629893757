export default {
    marketingTimeline: [
        {
            status: "Oprettelser",
            description:
                "Fælles: Vi ønsker at få oprettet og opsat nederstående punkter for at kunne komme i gang med annoncering.",
            checkbox: [
                {
                    label: "Indgå samarbejde via minmarketing.dk med marketing bureau",
                    value: false,
                },
                {
                    label: "Oprettelse af virksomheds facebook konto",
                    value: false,
                },
                {
                    label: "Oprettelse af Facebook Business Manager",
                    value: false,
                },
                {
                    label: "Oprettelse af Annoncekonti",
                    note: 'VIGTIGT: tidszone sættes til Europe/Copenhagen og valuta til DKK for danske virksomheder',
                    value: false,
                },
                {
                    label: "Oprettelse af Facebook Pixel",
                    value: false,
                },
            ],
            icon: "pi pi-check",
            color: "green",
            image: "game-controller.jpg",
        },
        {
            status: "Adgang",
            description: "Fælles: Der skal deles de nødvendige rettigheder mellem marketing bureau og forretning igennem facebook business manager.",
            checkbox: [
                {
                    label: "Giv partner adgang til marketing bureau",
                    note: "Under “Brugere -> Partnere” gøres marketingbureauet til partner.",
                    value: false,
                },
            ],
            icon: "pi pi-check",
            color: "green",
            image: "game-controller.jpg",
        },
        {
            status: "Administrative opgaver",
            description: "Forretning: Der skal deles de nødvendige rettigheder mellem marketing bureau og forretning igennem facebook business manager.",
            checkbox: [
                {
                    label: "Opdater virksomhedsoplysninger i facebook business manager",
                    note: "Under “virksomhedsoplysninger” er det vigtigt at udfylde alle nødvendige informationer for at facebook kan validere dig bedre.",
                    value: false,
                },
                {
                    label: "Tilføj betalingsmetode",
                    value: false,
                },
                {
                    label: "Opdater sikkerhed",
                    note: "Dette kan være vigtigt fordi facebook af sikkerhedsmæssige årsager af og til lukker annoncekontier hvis de er inaktive i længere perioder eller hvis de ikke er 2 trins godkendte",
                    value: false,
                },
                {
                    label: "Slå 2 trins godkendelse til",
                    value: false,
                },
                {
                    label: "Verificer business manager",
                    note: "Under “Sikkerhedscenter” er det vigtigt at når tiden er inde, så verificerer man sin business manager. Dette kommer først noget tid efter oprettelse. ",
                    value: false,
                },
                {
                    label: "Verificering af domæne",
                    note: "Brandsikkerhed og -egnethed -> domæner -> Tilføj -> Vælg indstilling: Opdater DNS TXT-registreringen med din domæneregistrator. -> Kopier linket i step to og opret en DNS rekord for verificering. ",
                    value: false,
                },
            ],
            icon: "pi pi-check",
            color: "green",
        },
        {
            status: "Tracking & klargørelse til annoncestart",
            description: "Marketing bureau: Der skal opsættes tracking på virksomhedens hjemmeside til at indsamle data til annonceringskonverteringer.",
            checkbox: [
                {
                    label: "Opsætning af Facebook Pixel mod virksomhedens hjemmeside",
                    value: false,
                },
                {
                    label: "Konfigurer hændelser på hjemmeside via facebook pixel",
                    value: false,
                },
                {
                    label: "Klargøring af annonceringskonto opstart af annoncering",
                    value: false,
                },
            ],
            icon: "pi pi-check",
            color: "green",
        },
    ],

}