import { resolve } from 'core-js';
import axios from '../../axios-auth'
import subscriptionActions from './stripe/billing/subscriptionActions';
import customerActions from './stripe/core_resources/customersActions';
import paymentMethodActions from './stripe/payment_methods/paymentMethodActions';

export default {
    ...paymentMethodActions,
    ...customerActions,
    ...subscriptionActions,
    createCustomer({ state }, formData) {
        axios.post('/payment/customer', { formData }, {
            headers: { "Authorization": `Bearer ${state.token}` }
        })
            .then(res => {
                resolve(res);
            })
            .catch(error => {
                return error;
            })
    },

    async createPaymentIntent({ state }) {
        return axios.post('/stripe/core_resources/payment_intents',
            {
                customerId: state.user.subscriptionPlan.stripeCustomerId
            })
            .then(res => {
                console.log(res.data.data);
                return res.data.data;
            })
            .catch(error => {
                console.log('Error from dispatcher');
                console.log(error);
                return error;
            })
    },
}