import { IGoogleData } from '@/datastructures';

export default {
  disconnectGoogleConnection: (state: any) => {
    state.user.platformCredentials.google.connected = false;
    state.user.platformCredentials.google.data = {};
  },

  connectGoogle: (state: any, googleData: IGoogleData) => {
    state.user.platformCredentials.google.connected = true;
    state.user.platformCredentials.google.data = {
      accessToken: googleData.accessToken,
      refreshToken: googleData.refreshToken,
      expiresIn: googleData.expiresIn,
      savedAt: googleData.savedAt,
    };
  },

  setSelectedGoogleAdsCustomer: (state: any, customer: any) => {
    state.user.marketingData.google.selectedCustomer = customer;
  },
  removeSelectedGoogleAdsCustomer: (state: any) => {
    state.user.marketingData.google.selectedCustomer = null;
  },
  removeGoogleAdsCustomers: (state: any) => {
    state.user.platformCredentials.google.data = [];
  },

  // Campaigns
  setGoogleCampaigns(state: any, campaigns: any) {
    state.user.marketingData.google.campaigns = campaigns;
  },
  setGoogleCampaignGeneralMetrics(state: any, generalMetrics: any) {
    state.user.marketingData.google.generalMetrics = generalMetrics;
  }
};
