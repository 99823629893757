import axios from '../../../../axios-auth';

// Service
import { MetaService } from '@/service/medias/MetaService';

// Initialize service
const metaService = new MetaService();

const routePath = '/meta/instagram';

export default {
  async getDailyInsight({ commit, getters }, instagramAccountId) {
    console.log("ACTION: getDailyInsight() -- instagramAccountId: " + instagramAccountId)

    if (!instagramAccountId) {
      throw new Error('Instagram ID not found');
    }

    return axios
      .get(`${routePath}/dailyInsight/${instagramAccountId}`, {
        params: {
          access_token: getters.getUserMetaCredentials.accessToken,
          metric: 'likes,profile_views,total_interactions',
          period: 'day',
          metric_type: 'total_value',
        },
      })
      .then((res) => {
        commit('storeInstagramDailyInsights', res.data);
      })
      .catch((error) => {
        console.log('action error', error.message);
        throw error;
      });
  },

  async getWeeklyInsight({ commit, getters }, instagramAccountId) {
    if (!instagramAccountId) {
      throw new Error('Instagram ID not found');
    }

    return axios
      .get(`${routePath}/weeklyInsight/${instagramAccountId}`, {
        params: {
          access_token: getters.getUserMetaCredentials.accessToken,
          metric: 'impressions,reach',
          period: 'week',
        },
      })
      .then((res) => {
        commit('storeInstagramWeeklyInsights', res.data);
      })
      .catch((error) => {
        console.log('action error', error.message);
        throw error;
      });
  },

  async fetchInstagramPosts({ commit, getters }, instagramAccountId) {
    if (!instagramAccountId) {
      throw new Error('Instagram ID not found');
    }

    return axios
      .get(`${routePath}/media/${instagramAccountId}`, {
        params: {
          access_token: getters.getUserMetaCredentials.accessToken,
        },
      })
      .then((res) => {
        commit('storeInstagramPosts', res.data);
      })
      .catch((error) => {
        throw error;
      });
  },

  async fetchInstagramPostInsight({ getters }, postId) {
    const instagramPostId = postId;

    return axios
      .get(`${routePath}/media/${instagramPostId}/insights`, {
        params: {
          access_token: getters.getUserMetaCredentials.accessToken,
          IG_MEDIA_ID: instagramPostId,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log('fetchInstagramPosts action error', error.message);
        throw error;
      });
  },

  async fetchStoredInstagramId({ state, commit, getters }) {
    const facebookPagesList = getters.getFacebookPagesPlatformCredentials.data;
    const getMetaPageSelection = metaService.getSelectedFacebookPage(facebookPagesList);

    // TODO: Implement elsewhere to seperate responsibilities
    commit("storeSelectedFacebookPage", getMetaPageSelection);

    if (getMetaPageSelection) {
      return getMetaPageSelection?.instagram_business_account?.id || null;
    }

    console.log('getStoredInstagramId: did get this far');

    if (state.user.platformCredentials.meta.data.pages.data.length > 0) {
      const foundPage = state.user.platformCredentials.meta.data.pages.data.find((page) => page.instagram_business_account && page.instagram_business_account.id);
      if (foundPage) {
        console.log('Setting page with Instagram ID in local storage');
        metaService.setMetaPageSelection(foundPage);
        return foundPage.instagram_business_account.id;
      }
    }

    return null;
  },
};
