// Helper function to calculate trend percentage and determine color
function calculateTrendResult(trendPercentage) {
    const color = trendPercentage > 0
        ? 'text-green-500'
        : trendPercentage < 0
            ? 'text-red-500'
            : 'text-black';

    return trendPercentage === null
        ? { value: null, color }
        : { value: `${trendPercentage > 0 ? '+' : ''}${Math.round(trendPercentage)}%`, color };
}

export function calculateTotalAverageCPC(campaigns, selectedTimePeriod) {
    let totalSpend = 0;
    let totalClicks = 0;

    const daysToSum = selectedTimePeriod.value === 'day'
        ? 1
        : selectedTimePeriod.value === 'week'
        ? 7
        : selectedTimePeriod.value === 'month'
        ? 28
        : Number.MAX_SAFE_INTEGER;

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            const relevantData = daysToSum === Number.MAX_SAFE_INTEGER ? campaign.insights.data : campaign.insights.data.slice(-daysToSum);

            relevantData.forEach(entry => {
                const spend = parseFloat(entry.spend);
                const linkClicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;

                if (!isNaN(spend) && spend > 0) {
                    totalSpend += spend;
                    totalClicks += parseInt(linkClicks, 10);
                }
            });
        }
    });

    return (totalClicks > 0 ? (totalSpend / totalClicks).toFixed(2) : "0.00");
}

export function calculatePriorAverageCPC(campaigns, selectedTimePeriod) {
    let priorTotalSpend = 0;
    let priorTotalClicks = 0;

    const daysToSum = selectedTimePeriod.sumDays;
    const daysToSkip = daysToSum;

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            const data = campaign.insights.data;
            if (data.length >= daysToSum + daysToSkip) {
                const relevantData = data.slice(-daysToSum - daysToSkip, -daysToSkip);
                relevantData.forEach(entry => {
                    const spend = parseFloat(entry.spend);
                    const linkClicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;

                    if (parseInt(linkClicks, 10) > 0) {
                        priorTotalSpend += spend;
                        priorTotalClicks += parseInt(linkClicks, 10);
                    }
                });
            }
        }
    });

    return (priorTotalClicks > 0 ? (priorTotalSpend / priorTotalClicks).toFixed(2) : "0.00");
}

export function calculateTotalAverageCPCTrendPercentage(campaigns, selectedTimePeriod) {
    let startPeriodTotalSpend = 0;
    let endPeriodTotalSpend = 0;
    let startPeriodTotalClicks = 0;
    let endPeriodTotalClicks = 0;

    const periodLength = selectedTimePeriod.sumDays;

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data && campaign.insights.data.length >= 2 * periodLength) {
            const data = campaign.insights.data;
            const startPeriodData = data.slice(-(2 * periodLength), -periodLength);
            const endPeriodData = data.slice(-periodLength);

            startPeriodData.forEach(entry => {
                const spend = parseFloat(entry.spend);
                const clicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;
                startPeriodTotalSpend += spend;
                startPeriodTotalClicks += parseInt(clicks, 10);
            });

            endPeriodData.forEach(entry => {
                const spend = parseFloat(entry.spend);
                const clicks = entry.actions.find(action => action.action_type === 'link_click')?.value || 0;
                endPeriodTotalSpend += spend;
                endPeriodTotalClicks += parseInt(clicks, 10);
            });
        }
    });

    const startPeriodAverageCPC = startPeriodTotalClicks > 0 ? startPeriodTotalSpend / startPeriodTotalClicks : 0;
    const endPeriodAverageCPC = endPeriodTotalClicks > 0 ? endPeriodTotalSpend / endPeriodTotalClicks : 0;
    const trendPercentage = startPeriodAverageCPC !== 0 ? ((endPeriodAverageCPC - startPeriodAverageCPC) / startPeriodAverageCPC) * 100 : 0;

    return calculateTrendResult(trendPercentage);
}

export function calculateTotalAverageCPCInDays(campaigns) {
    const cpcSumsAndCounts = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            campaign.insights.data.forEach(entry => {
                const { date_stop, spend, actions } = entry;
                if (!cpcSumsAndCounts[date_stop]) {
                    cpcSumsAndCounts[date_stop] = { totalSpend: 0, totalClicks: 0 };
                }

                const spendValue = parseFloat(spend);
                const linkClicks = actions.find(action => action.action_type === 'link_click')?.value || 0;
                const linkClicksCount = parseInt(linkClicks, 10);

                if (linkClicksCount > 0) {
                    cpcSumsAndCounts[date_stop].totalSpend += spendValue;
                    cpcSumsAndCounts[date_stop].totalClicks += linkClicksCount;
                }
            });
        }
    });

    return Object.keys(cpcSumsAndCounts).map(date => {
        const { totalSpend, totalClicks } = cpcSumsAndCounts[date];
        return { date: date, cpc: totalClicks > 0 ? (totalSpend / totalClicks).toFixed(2) : "0.00" };
    }).sort((a, b) => new Date(a.date) - new Date(b.date));
}

export function calculateTotalAverageCPCInWeeks(campaigns) {
    const cpcSumsAndCountsByWeek = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInWeeks) {
            campaign.insights.dataInWeeks.forEach(weekData => {
                const week = weekData.date_start;
                if (!cpcSumsAndCountsByWeek[week]) {
                    cpcSumsAndCountsByWeek[week] = { sum: 0, count: 0 };
                }

                const cpcValue = parseFloat(weekData.cpc);
                if (cpcValue > 0) {
                    cpcSumsAndCountsByWeek[week].sum += cpcValue;
                    cpcSumsAndCountsByWeek[week].count += 1;
                }
            });
        }
    });

    return Object.keys(cpcSumsAndCountsByWeek).map(week => {
        const { sum, count } = cpcSumsAndCountsByWeek[week];
        return { week, cpc: count > 0 ? (sum / count).toFixed(2) : "0.00" };
    }).sort((a, b) => a.week.localeCompare(b.week));
}

export function calculateTotalAverageCPCInMonths(campaigns) {
    const cpcSumsAndCountsByMonth = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInMonths) {
            campaign.insights.dataInMonths.forEach(monthData => {
                const month = monthData.date_start;
                if (!cpcSumsAndCountsByMonth[month]) {
                    cpcSumsAndCountsByMonth[month] = { sum: 0, count: 0 };
                }

                const cpcValue = parseFloat(monthData.cpc);
                if (cpcValue > 0) {
                    cpcSumsAndCountsByMonth[month].sum += cpcValue;
                    cpcSumsAndCountsByMonth[month].count += 1;
                }
            });
        }
    });

    return Object.keys(cpcSumsAndCountsByMonth).map(month => {
        const { sum, count } = cpcSumsAndCountsByMonth[month];
        return { month, cpc: count > 0 ? (sum / count).toFixed(2) : "0.00" };
    }).sort((a, b) => new Date(a.month + "-01") - new Date(b.month + "-01"));
}

export function calculateTotalAverageCPCInYears(campaigns) {
    const cpcSumsAndCountsByYear = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInYears) {
            campaign.insights.dataInYears.forEach(yearData => {
                const year = yearData.date_start;
                if (!cpcSumsAndCountsByYear[year]) {
                    cpcSumsAndCountsByYear[year] = { sum: 0, count: 0 };
                }

                const cpcValue = parseFloat(yearData.cpc);
                if (cpcValue > 0) {
                    cpcSumsAndCountsByYear[year].sum += cpcValue;
                    cpcSumsAndCountsByYear[year].count += 1;
                }
            });
        }
    });

    return Object.keys(cpcSumsAndCountsByYear).map(year => {
        const { sum, count } = cpcSumsAndCountsByYear[year];
        return { year, cpc: count > 0 ? (sum / count).toFixed(2) : "0.00" };
    }).sort((a, b) => a.year.localeCompare(b.year));
}
