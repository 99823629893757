export default {
  isFacebookAdsAccount: (state: any) => {
    return state.user.platformCredentials.meta.data.adAccounts.connected;
  },
  isFacebookPagesConnected: (state: any) => {
    return state.user.platformCredentials.meta.data.pages.connected;
  },

  getMetaPlatformCredentials: (state: any) => {
    return state.user.platformCredentials.meta
  },

  getFacebookPagesPlatformCredentials: (state: any) => {
    return state.user.platformCredentials.meta.data.pages
  },

  facebookAuthToken: (state: any) => {
    return state.user.platformCredentials.meta.data.accessToken;
  },
};
