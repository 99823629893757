import { toRefs, reactive, computed } from 'vue';

const layoutConfig = reactive({
    ripple: false,
    inputStyle: 'outlined',
    menuMode: 'static',
    menuTheme: 'colorScheme',
    colorScheme: 'light',
    theme: 'indigo',
    scale: 14
});

const layoutState = reactive({
    staticMenuDesktopInactive: false,
    overlayMenuActive: false,
    sidebarActive: false,
    anchored: false,
    overlaySubmenuActive: false,
    profileSidebarVisible: false,
    configSidebarVisible: false,
    staticMenuMobileActive: false,
    menuHoverActive: false,
    activeMenuItem: null
});

const updateThemeFromLocalStorage = () => {
    const themePreferenceJSON = localStorage.getItem('appearancePreference');
    const themePreference = themePreferenceJSON ? JSON.parse(themePreferenceJSON) : null;
    const savedTheme = themePreference ? themePreference.theme : 'light';
    layoutConfig.colorScheme = savedTheme; // Update reactive state
};

const updateScaleFromLocalStorage = () => {
    const appearancePreferencesJSON = localStorage.getItem('appearancePreference');
    const appearancePreferences = appearancePreferencesJSON ? JSON.parse(appearancePreferencesJSON) : {};
    if (appearancePreferences.scale) {
        useLayout().setScale(appearancePreferences.scale);
        document.documentElement.style.fontSize = appearancePreferences.scale + 'px';
    }
};

const updateMenuThemeFromLocalStorage = () => {
    const appearancePreferencesJSON = localStorage.getItem('appearancePreference');
    const appearancePreferences = appearancePreferencesJSON ? JSON.parse(appearancePreferencesJSON) : {};
    if (appearancePreferences.menu) {
        layoutConfig.menuTheme = appearancePreferences.menu;
    }
};

updateThemeFromLocalStorage();
updateScaleFromLocalStorage();
updateMenuThemeFromLocalStorage();

export function useLayout() {

    const setScale = (scale) => {
        layoutConfig.scale = scale;
        // Consider saving the scale to localStorage here as well to keep it updated
    };

    const setActiveMenuItem = (item) => {
        layoutState.activeMenuItem = item.value || item;
    };

    const onMenuToggle = () => {
        if (layoutConfig.menuMode === 'overlay') {
            layoutState.overlayMenuActive = !layoutState.overlayMenuActive;
        }

        if (window.innerWidth > 991) {
            layoutState.staticMenuDesktopInactive = !layoutState.staticMenuDesktopInactive;
        } else {
            layoutState.staticMenuMobileActive = !layoutState.staticMenuMobileActive;
        }
    };

    const onProfileSidebarToggle = () => {
        layoutState.profileSidebarVisible = !layoutState.profileSidebarVisible;
    };

    const setProfileSidebarToggleToFalse = () => {
        layoutState.profileSidebarVisible = false;
    };

    const onConfigSidebarToggle = () => {
        layoutState.configSidebarVisible = !layoutState.configSidebarVisible;
    };

    const isSidebarActive = computed(() => layoutState.overlayMenuActive || layoutState.staticMenuMobileActive || layoutState.overlaySubmenuActive);

    const isDarkTheme = computed(() => layoutConfig.darkTheme);

    const isDesktop = computed(() => window.innerWidth > 991);

    const isSlim = computed(() => layoutConfig.menuMode === 'slim');
    const isSlimPlus = computed(() => layoutConfig.menuMode === 'slim-plus');

    const isHorizontal = computed(() => layoutConfig.menuMode === 'horizontal');

    return {
        layoutConfig: toRefs(layoutConfig),
        layoutState: toRefs(layoutState),
        setScale,
        onMenuToggle,
        isSidebarActive,
        isDarkTheme,
        setActiveMenuItem,
        onProfileSidebarToggle,
        setProfileSidebarToggleToFalse,
        onConfigSidebarToggle,
        isSlim,
        isSlimPlus,
        isHorizontal,
        isDesktop
    };
}
