import axios from '../../axios-auth'

// Storage route path
const routePath = '/marketing/marketingTimeline';

export default {
  getMarketingTimeline({ commit }, userUUID) {
    return axios.get(`${routePath}/${userUUID}`)
      .then((res) => {
        commit('storeMarketingTimeline', res.data.metaMarketingTimeline.marketingTimeline)
        return res.data;
      })
      .catch((error) => {
        console.log('action error', error);
        throw error;
      });
  },


  async storeMarketingTimeline({ }, { userUUID, marketingTimeline }) {
    return axios.post(`${routePath}/${userUUID}`, { marketingTimeline: marketingTimeline })
      .then((res) => {
        // console.log('storeMarketingTimeline',  .data);
        return res.data;
      })
      .catch((error) => {
        console.log('action error', error);
        throw error;
      });
  },
}