import axios from '../../axios-auth'
import { checkForSelectedPartnerAndReturnUUID } from "@/service/users/userCheck";

// Storage route path
const routePath = '/storage';

export default {
    getFiles({ commit }, uuid) {
        axios.get(`${routePath}/folder/${uuid}`)
            .then((res) => {
                commit('storeFiles', res.data.files)
                commit('storeFolders', res.data.files)
                commit('storeFilesCategories', res.data.files)
                commit('storeFileTotalSize', res.data.totalFileSize)
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    async getFileUrl({ state }, fileName) {
        if (!state.token) {
            return;
        }

        let result;

        await axios.get(`${routePath}/fileUrl?fileName=${fileName}`)
            .then((res) => {
                result = res.data.url
                return result;
            })
            .catch((error) => {
                console.log('action error', error);
            });

        return result;
    },

    async uploadFile({ state, dispatch }, formData) {
        return axios.post(`${routePath}/file`, formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then((res) => {
                dispatch('getFiles', checkForSelectedPartnerAndReturnUUID(state.user.uuid))
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    async downloadFile({ state }, file) {
        if (!state.token) {
            return;
        }

        await axios.get(`${routePath}/download?fileName=${file.fullName}`, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: res.headers['content-type'] });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                link.click();

                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                throw error;
            });
    },

    moveFileToFolder({ commit }, filePayload) {
        axios.post(`${routePath}/moveFile`, filePayload)
            .then((result) => {

                console.log('Return from backend');
                console.log(result);

                commit('moveFileToFolder', { filePayload, result })
            })
            .catch((error) => {
                throw error;
            });
    },

    deleteFile({ state, commit }, fileName) {
        if (!state.token) {
            return;
        }

        axios.delete(`${routePath}/file?fileName=${fileName}`)
            .then((res) => {
                commit('removeFileFromStore', fileName)
            })
            .catch((error) => {
                console.log('action error', error);
            });
    },

    // Folders
    createFolder({ state, commit }, folderName) {
        const userUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid)
        console.log('userUUID', userUUID);
        console.log('folderName', folderName);
        axios.post(`${routePath}/folder/${userUUID}?folderName=${folderName}`)
            .then((res) => {
                commit('addFolderToStore', folderName)
            })
    },
    deleteFolderAndContent({ state, commit, dispatch }, data) {
        const validatedUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid)
        axios.delete(`${routePath}/folder/${validatedUUID}?folderName=${data.folderName}`)
            .then((res) => {
                dispatch('getFiles', validatedUUID)
                commit('removeFolderFromStore', data.folderName)
            })
            .catch((error) => {
                console.log('action error', error);
            });
    }
}