import axios from 'axios';

// Store
import store from './store';

const instance = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_BASE_URL
})

// Add a request interceptor
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
)

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

// INTERCEPTOR: Validate token
instance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        console.log('Error status: ', error.response.status);
        const originalRequest = error.config;

        // Skip if given url
        if (originalRequest.url.includes('/auth/signin') || 
            originalRequest.url.includes('/auth/signup')) {
            return Promise.reject(error);
        }

        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then(token => {
                    originalRequest.headers['Authorization'] = 'Bearer ' + token;
                    return instance(originalRequest);
                }).catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = localStorage.getItem('refreshToken');
            return new Promise((resolve, reject) => {
                instance.post('/auth/refreshAccessToken', { refreshToken: refreshToken })
                    .then((res) => {
                        if (res.status === 201) {
                            localStorage.setItem('token', res.data.token);
                            instance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token;
                            processQueue(null, res.data.token);
                            resolve(instance(originalRequest));
                        } else {
                            processQueue(new Error('Failed to refresh token'));
                            store.dispatch('signout');
                            reject(new Error('Failed to refresh token'));
                        }
                    })
                    .catch((err) => {
                        processQueue(err);
                        store.dispatch('signout');
                        reject(err);
                    })
                    .finally(() => {
                        isRefreshing = false;
                    });
            });
        }
        return Promise.reject(error);
    }
)

export default instance;