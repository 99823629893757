export default {
  marketingData: {
    meta: {
      adAccountsObject: {
        adAccounts: [],
        selectedAdAccount: null,
      },
      campaigns: {
        generalMetrics: {
          spend: {
            totalSpend: null,
            priorTotalSpend: null,
            totalSpendArray: [],
            totalSpendInWeeks: [],
            totalSpendInMonths: [],
            totalSpendInYears: [],
          },
          cost_per_unique_click: {
            totalAverageCPC: null,
            priorAverageCPC: null,
            totalAverageCPCTrendPercentage: {
              value: null,
              color: null,
            },
            totalDataInDays: [],
            totalDataInWeeks: [],
            totalDataInMonths: [],
            totalDataInYears: [],
          },
          impressions: {
            totalImpressions: null,
            priorTotalImpressions: null,
            totalImpressionsTrendPercentage: null,
            totalImpressionsArray: [],
          },
          clicksOnAd: {
            totalClicksOnAd: null,
            priorTotalClicksOnAd: null,
            totalClicksOnAdTrendPercentage: null,
            totalClicksOnAdDays: [],
            totalClicksOnAdInWeeks: [],
            totalClicksOnAdInMonths: [],
            totalClicksOnAdInYears: [],
          },
          reach: {
            totalReach: null,
            priorTotalReach: null,
            totalReachTrendPercentage: null,
            totalReachArray: [],
          },
          averageFrequency: {
            totalAverageFrequency: null,
            priorTotalAverageFrequency: null,
            totalAverageFrequencyTrendPercentage: null,
            totalAverageFrequencyArray: [],
          },
          pageEngagement: {
            totalPageEngagement: null,
            priorTotalPageEngagement: null,
            totalPageEngagementTrendPercentage: null,
            totalPageEngagementArray: [],
          },
          postReactions: {
            totalPostReactions: null,
            priorTotalPostReactions: null,
            totalPostReactionsTrendPercentage: null,
            totalPostReactionsArray: [],
          },
        },
        campaignList: [],
      },
      selectedMarketingInsightData: [],
      instagram: {
        posts: [],
        dailyInsights: [],
        weeklyInsights: [],
        monthlyInsights: [],
      },
      facebook: {
        pages: [],
        selectedPage: null,
        posts: [],
      },
    },
    google: {
      campaigns: null,
      generalMetrics: {
        clicksOnAd: null,
        impressions: null,
        spend: null,
      },
    },
    twitter: {},
    linkedin: {},
    total: {
      data: {
        totalSpend: null,
        totalImpressions: null,
        totalClicksOnAd: null,
        totalReach: null,
        totalAverageFrequency: null,
        totalPageEngagement: null,
        totalPostReactions: null,
      }
    } 
  }
};
