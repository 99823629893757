export default {
    getSelectedAdAccount: (state) => {
        return state.user.marketingData.meta.adAccountsObject.selectedAdAccount;
    },
    getAdAccounts: (state) => {
        return state.user.marketingData.meta.adAccountsObject.adAccounts;
    },
    
    adAccountInsights: (state) => {
        return state.user.marketingData.meta.selectedMarketingInsightData;
    },

}
