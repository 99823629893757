// Define the state interface for the contacts module
import { IPersonnel, ITodo, IContactObject, PersonnelRole } from '@/types/contacts/Contact'; // Adjust the import path as necessary

// Define the state interface for the contacts module
interface ContactsState {
  contacts: IContactObject[]; // Array of contact objects
  selectedContact: IContactObject | null; // Single selected contact for detailed view/editing
}

// Define the initial state
const state: ContactsState = {
    contacts: [],
    selectedContact: null,
  };

  export default {
    // Store multiple contact objects in state (entire contacts array)
    storeContacts(state: ContactsState, payload: IContactObject[]) {
      state.contacts = payload;
    },
  
    // Store a single contact object in state (e.g., for detailed view or editing)
    storeContact(state: ContactsState, payload: IContactObject) {
      state.selectedContact = payload;
    },
  
    // Add a new contact object to the contacts array in state
    addContact(state: ContactsState, payload: IContactObject) {
      state.contacts.push(payload);
    },
  
    // Update an existing contact object in the contacts array by UUID
    updateContact(state: ContactsState, payload: IContactObject) {
      const index = state.contacts.findIndex((contact) => contact.uuid === payload.uuid);
      if (index !== -1) {
        state.contacts.splice(index, 1, payload); // Replace the existing contact with the updated one
      }
    },
  
    // Remove a contact object from the contacts array by UUID
    removeContact(state: ContactsState, uuid: string) {
      state.contacts = state.contacts.filter((contact) => contact.uuid !== uuid);
    },
  
    // Add a new personnel to the selected contact's personnel array
    addPersonnelToContact(state: ContactsState, { uuid, personnel }: { uuid: string; personnel: IPersonnel }) {
      const contact = state.contacts.find((contact) => contact.uuid === uuid);
      if (contact && contact.personnel) {
        contact.personnel.push(personnel);
      }
    },
  
    // Update a personnel in the selected contact's personnel array
    updatePersonnelInContact(
      state: ContactsState,
      { uuid, index, personnel }: { uuid: string; index: number; personnel: IPersonnel }
    ) {
      const contact = state.contacts.find((contact) => contact.uuid === uuid);
      if (contact && contact.personnel && contact.personnel[index]) {
        contact.personnel.splice(index, 1, personnel);
      }
    },
  
    // Remove a personnel from the selected contact's personnel array
    removePersonnelFromContact(state: ContactsState, { uuid, index }: { uuid: string; index: number }) {
      const contact = state.contacts.find((contact) => contact.uuid === uuid);
      if (contact && contact.personnel) {
        contact.personnel.splice(index, 1);
      }
    },
  
    // Add a new to-do item to the selected contact's todos array
    addTodoToContact(state: ContactsState, { uuid, todo }: { uuid: string; todo: ITodo }) {
      const contact = state.contacts.find((contact) => contact.uuid === uuid);
      if (contact && contact.todos) {
        contact.todos.push(todo);
      }
    },
  
    // Update a to-do item in the selected contact's todos array
    updateTodoInContact(
      state: ContactsState,
      { uuid, index, todo }: { uuid: string; index: number; todo: ITodo }
    ) {
      const contact = state.contacts.find((contact) => contact.uuid === uuid);
      if (contact && contact.todos && contact.todos[index]) {
        contact.todos.splice(index, 1, todo);
      }
    },
  
    // Remove a to-do item from the selected contact's todos array
    removeTodoFromContact(state: ContactsState, { uuid, index }: { uuid: string; index: number }) {
      const contact = state.contacts.find((contact) => contact.uuid === uuid);
      if (contact && contact.todos) {
        contact.todos.splice(index, 1);
      }
    },
  };