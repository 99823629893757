<script setup>
import { ref, onMounted } from 'vue';

// Components
import MembershipPlans from '@/views/payment/MembershipPlans.vue';
import MembershipPlan from '@/views/payment/MembershipPlan.vue';

const { visible, userSubscriptionPlan } = defineProps(['visible', 'userSubscriptionPlan']);
const emit = defineEmits();

// Store
import { useStore } from "vuex";
const store = useStore();

// Component mounted
onMounted(() => {
  store.dispatch('fetchSubscriptionPlans');
});

// Functions
const closeModal = () => {
  emit('update:visible', false);
  selectedMembershipPlan.value = null;
}

const selectedMembershipPlan = ref(null);

function goBack() {
  selectedMembershipPlan.value = null;
}

function toogleFavorite(plan) {
  selectedMembershipPlan.value = plan;
}

</script>

<template>
  <Dialog class="membership-dialog-width" modal :visible="visible" :showHeader="false" @update:visible="closeModal"
    :update="visible" position="center" :draggable="false">
    <div class="absolute top-0 right-0 flex align-items-center justify-content-center w-4rem h-4rem border-round">
      <Button @click="closeModal" icon="pi pi-times" severity="secondary" text rounded aria-label="Cancel" />
    </div>

    <MembershipPlans v-if="selectedMembershipPlan == null" :userSubscriptionPlan="userSubscriptionPlan"
      @selected-membership-plan="toogleFavorite" />

    <div v-if="selectedMembershipPlan != null"
      class="absolute top-0 left-0 flex align-items-center justify-content-center w-6rem h-6rem">
      <i @click="goBack" class="pi pi-arrow-left" style="color: black; font-size: 1.5rem" text rounded
        aria-label="Cancel"></i>
    </div>
    <MembershipPlan v-if="selectedMembershipPlan != null" :selectedMembershipPlan="selectedMembershipPlan" />

  </Dialog>
</template>

<style lang="scss">
i:hover {
  cursor: pointer;
}

.membership-dialog-width {
  width: 60%;
  max-width: 100rem;
}

@media only screen and (max-width: 768px) {
  .membership-dialog-width {
    width: 95%;
  }
}
</style>
