import axios from '../../../axios-auth'

const routePath = '/meta';

export default {
    initMetaUser({ commit, state, dispatch }, userData) {
        console.log('initMetaUser', userData);
        return axios.post(`${routePath}/initUser/${state.user.uuid}`, userData)
            .then((res) => {
                console.log('ACTION: Success -- initMetaUser action res');
                console.log(res);

                const updatedUser = res.data.data

                if (updatedUser.platformCredentials.meta.data.pages.data.length > 0) {
                    commit('storeFacebookPages', updatedUser.platformCredentials.meta.data.pages.data)
                }

                commit('setMetaAuthenticated', true);
                commit('storeUser', res.data.data)
                dispatch('getAdAccounts');
                return res;
            })
            .catch((error) => {
                console.log('action error', error);
                throw error;
            });
    },
}