import axios from '../../../../axios-auth';
import { ActionContext } from 'vuex';
import { State, FacebookState, FacebookPost } from '@/types'; // Adjust the import paths and types as needed
import { FacebookPage } from '@/types/meta/facebook/pageType';
import { MetaService } from '@/service/medias/MetaService';
import { routerKey } from 'vue-router';

// Initialize service
const metaService = new MetaService();

const routePath = '/meta/facebook';

// Define types for the Vuex context and mutations
interface Mutations {
  storeFacebookPosts: (state: FacebookState, payload: FacebookPost[]) => void;

  // Pages
  storePages: (state: FacebookState, payload: FacebookPage[]) => void;
  storeSelectedPage: (state: FacebookState, payload: FacebookPage) => void;
}

// Type for the Action Context
type FacebookActionContext = ActionContext<FacebookState, State> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

export default {
  async fetchFacebookPages({ commit, getters }: FacebookActionContext): Promise<FacebookPage[]> {
    try {
      const metaAccessToken = getters.facebookAuthToken;

      const response = await axios.get<{ success: boolean; data: FacebookPage[] }>(`${routePath}/pages`, {
        params: { access_token: metaAccessToken },
      });

      const pages: FacebookPage[] = response.data.data;

      console.log('Testong to see this: ');
      console.log(pages);

      commit('storeFacebookPages', pages);

      return pages;
    } catch (error) {
      throw error;
    }
  },

  // Set the selected Facebook page by its ID
  async selectPageById({ commit, state }: FacebookActionContext, pageId: string): Promise<void> {
    const selectedPage = state.pages.find((page) => page.id === pageId); // Find page in the state by ID

    if (selectedPage) {
      commit('storeSelectedPage', selectedPage); // Commit selected page to Vuex store using the 'storeSelectedPage' mutation
    }
  },

  // Action to fetch Facebook page posts
  async fetchFacebookPagePosts({ commit }: FacebookActionContext): Promise<void> {
    try {
      const getMetaPageSelection = metaService.getMetaPageSelection();
      const pageId = getMetaPageSelection.id;

      if (!pageId || !getMetaPageSelection.access_token) {
        throw new Error('Missing page ID or access token');
      }

      const response = await axios.get<{ success: boolean; data: FacebookPost[] }>(`${routePath}/${pageId}`, {
        params: { access_token: getMetaPageSelection.access_token },
      });

      const posts = response.data.data;

      // Commit the fetched posts to the Vuex store
      commit('storeFacebookPosts', posts);
    } catch (error) {
      throw error; // Handle or log error as needed
    }
  },

  async disconnectFacebookPages({ commit, getters }: FacebookActionContext): Promise<void> {
    console.log('Disconnecting Facebook page...');
    try {
      const response = await axios.patch<{ success: boolean }>(`${routePath}/disconnect`, {});

      // If the response indicates success, remove the Google connection from the state
      if (response.data.success) {
        commit('disconnectFacebookPages');
        commit('resetFacebookPagesAndInstagram');
        commit('resetSelectedFacebookPage');
      }
    } catch (error) {
      console.error('Failed to disconnect Google platform:', error);
      throw error;
    }
  },
};
