import log from 'loglevel';

const isProduction = process.env.NODE_ENV === 'production';

// Set the log level based on the environment
if (isProduction) {
    log.setLevel('info'); // Only log info and error in production
} else {
    log.setLevel('debug'); // Log debug, info, and error in development
}

export default log;