export default {
    storeInstagramDailyInsights(state, data) {
        state.user.marketingData.meta.instagram.dailyInsights = data.data
    },
    
    storeInstagramWeeklyInsights(state, data) {
        state.user.marketingData.meta.instagram.weeklyInsights = data.data
    },

    storeInstagramPosts(state, data) {
        state.user.marketingData.meta.instagram.posts = data
    }
}