<script setup>
import { computed, watch, ref, onBeforeUnmount, onMounted } from "vue";
import AppTopbar from "./AppTopbar.vue";
import AppSidebar from "./AppSidebar.vue";
import AppConfig from "./AppConfig.vue";
import AppProfileSidebar from "./AppProfileSidebar.vue";
import AppBreadCrumb from "./AppBreadcrumb.vue";
import { useLayout } from "@/layout/composables/layout";

// Components
import PublicLayout from '@/views/public/layout/PublicLayout.vue';
import AppInitializer from '@/views/Applnitializer.vue';
import store from "../store/store";

const { layoutConfig, layoutState, isSidebarActive } = useLayout();

const outsideClickListener = ref(null);
const sidebarRef = ref(null);
const topbarRef = ref(null);

// References
const isAuthenticated = ref(false);

watch(isSidebarActive, (newVal) => {
  if (newVal) {
    bindOutsideClickListener();
  } else {
    unbindOutsideClickListener();
  }
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const containerClass = computed(() => {
  return {
    "layout-light": layoutConfig.colorScheme.value === "light",
    "layout-dim": layoutConfig.colorScheme.value === "dim",
    "layout-dark": layoutConfig.colorScheme.value === "dark",
    "layout-colorscheme-menu": layoutConfig.menuTheme.value === "colorScheme",
    "layout-primarycolor-menu": layoutConfig.menuTheme.value === "primaryColor",
    "layout-transparent-menu": layoutConfig.menuTheme.value === "transparent",
    "layout-overlay": layoutConfig.menuMode.value === "overlay",
    "layout-static": layoutConfig.menuMode.value === "static",
    "layout-slim": layoutConfig.menuMode.value === "slim",
    "layout-slim-plus": layoutConfig.menuMode.value === "slim-plus",
    "layout-horizontal": layoutConfig.menuMode.value === "horizontal",
    "layout-reveal": layoutConfig.menuMode.value === "reveal",
    "layout-drawer": layoutConfig.menuMode.value === "drawer",
    "layout-static-inactive":
      layoutState.staticMenuDesktopInactive.value &&
      layoutConfig.menuMode.value === "static",
    "layout-overlay-active": layoutState.overlayMenuActive.value,
    "layout-mobile-active": layoutState.staticMenuMobileActive.value,
    "p-input-filled": layoutConfig.inputStyle.value === "filled",
    "p-ripple-disabled": layoutConfig.ripple.value,
    "layout-sidebar-active": layoutState.sidebarActive.value,
    "layout-sidebar-anchored": layoutState.anchored.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        layoutState.overlayMenuActive.value = false;
        layoutState.overlaySubmenuActive.value = false;
        layoutState.staticMenuMobileActive.value = false;
        layoutState.menuHoverActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  const sidebarEl = sidebarRef?.value.$el;
  const topbarEl = topbarRef?.value.$el.querySelector(".topbar-menubutton");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};

onMounted(() => {
  const token = localStorage.getItem('token');
  const expirationDate = localStorage.getItem('expirationDate');
  const refreshToken = localStorage.getItem('refreshToken');

  if (token && new Date() < new Date(expirationDate)) {
    isAuthenticated.value = true;
  } else if (refreshToken) {
    store.dispatch('refreshAccessToken', { refreshToken: refreshToken })
      .then(() => {
        isAuthenticated.value = true;
      })
      .catch(() => {
        isAuthenticated.value = false;
      });
  } else {
    isAuthenticated.value = false;
  }
});
</script>

<template>
  <div v-if="!isAuthenticated">
    <PublicLayout />
  </div>


  <div v-else>
    <div id="initializeApp">
      <AppInitializer />
    </div>


    <div :class="['layout-container', { ...containerClass }]">
      <AppSidebar ref="sidebarRef" />

      <div class="layout-content-wrapper">
        <AppTopbar ref="topbarRef" />
        <AppBreadCrumb class="content-breadcrumb"></AppBreadCrumb>
        <div class="layout-content">
          <router-view></router-view>
        </div>
      </div>

      <AppProfileSidebar />
      <AppConfig />

      <Toast></Toast>
      <div class="layout-mask"></div>
    </div>
  </div>
</template>
