import axios from '../../axios-auth';

const routePath = '/message';

export default {
  async getMessages({ commit }, userUUID) {
    return axios
      .get(`${routePath}/${userUUID}`)
      .then((res) => {
        commit('storeMessages', res.data.data);
        return res.data.data;
      })
      .catch(() => {
        return 'Error getting messages';
      });
  },

  async markMessageAsRead({ commit }, messageUUID) {
    return axios
      .put(`${routePath}/details/${messageUUID}`)
      .then((res) => {
        commit('markMessageAsRead', messageUUID);
        commit('removeMessageFromUnread', messageUUID);
        return res;
      })
      .catch(() => {
        throw 'Error deleting message';
      });
  },

  async sendMessage({ state, commit }, { receiver, messagePayload }) {
    const message = {
      fromUserUUID: state.user.uuid,
      fromCompanyName: state.user.companyName,
      toUserUUID: receiver.userUUID,
      toCompanyName: receiver.companyName,
      image: state.user.profile.image.url,
      title: messagePayload.title,
      message: messagePayload.message,
      important: false,
      starred: false,
      trash: false,
      archived: false,
      state: 'unread',
    };

    return axios
      .post(`${routePath}/${receiver.userUUID}`, { message })
      .then((res) => {
        commit('storeSentMessage', res.data.data);
        return res;
      })
      .catch(() => {
        return 'Error sending message';
      });
  },

  async deleteMessage({ state, commit }, messageUUID) {
    return axios
      .delete(`${routePath}/${state.user.uuid}?messageUUID=${messageUUID}`)
      .then((res) => {
        commit('deleteMessage', messageUUID);
        return res;
      })
      .catch(() => {
        throw 'Error deleting message';
      });
  },

  async addComment({ state, commit }, { receiverUUID, messageUUID, message }) {
    let payload = {
      receiverUUID: receiverUUID,
      comment: {
        fromUserUUID: state.user.uuid,
        fromCompanyName: state.user.companyName,
        message: message,
        profileImage: state.user.profile_image,
      },
    };

    return axios
      .put(`${routePath}/comments/${messageUUID}`, payload)
      .then((res) => {
        console.log('Successfully added the comment');
        console.log('res', res);
        commit('addCommentToMessage', { messageUUID, comment: payload.comment });
        return res;
      })
      .catch(() => {
        throw 'Error deleting message';
      });
  },
};
