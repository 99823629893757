import axios from '../../axios-auth'
import router from '../../../router'

const routePath = '/ai';

export default {
    async callOpenAI({ commit }, { prompt }) {
        return axios.post(`${routePath}/callOpenAI`, { prompt })
            .then((res) => {
                // Handle success
                return res.data.data; // Assuming the response data is under 'data'
            })
            .catch((error) => {
                // Handle error
                console.error('Error calling OpenAI:', error);
                throw error;
            });
    },
};