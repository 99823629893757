export default {
    getMetaCampaignList: (state) => {
        return state.user.marketingData.meta.campaigns.campaignList;
    },
    
    getAdCampaignsSpend: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.spend;
    },
    getAdCampagnsGeneralMetricsCPC: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.cost_per_unique_click;
    },
    getadCampaignsImpressions: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.impressions;
    },
    getAdCampaignsClicksOnAd: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.clicksOnAd;
    },
    getadCampaignsReach: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.reach;
    },
    getadCampaignsAverageFrequency: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.averageFrequency;
    },
    getadCampaignsPageEngagement: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.pageEngagement;
    },
    getadCampaignsPostReactions: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics.postReactions;
    },

    getMetaCampaignsGeneralMetrics: (state) => {
        return state.user.marketingData.meta.campaigns.generalMetrics;
    },
}
