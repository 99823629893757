import { NotificationEnum } from '@/datastructures/enums/notifications/NotificationEnum';

import emitter from '@/utils/eventBus';

export function handleNotification(notification: any, commit: any, state: any) {
  switch (notification.type) {
    case NotificationEnum.GENERIC:
      commit('pushNotificationToQueue', notification);
      emitter.emit('show-toast', {
        title: notification.title,
        message: notification.message,
        topic: 'message',
        toastType: 'info',
        toastDuration: 3000,
      });
      break;

    case NotificationEnum.NEW_MESSAGE:
      commit('pushNotificationToQueue', notification);
      emitter.emit('show-toast', {
        title: notification.title,
        message: notification.message,
        topic: 'message',
        toastType: 'info',
        toastDuration: 3000,
      });
      break;

    case NotificationEnum.NEW_COMMENT:
      commit('pushNotificationToQueue', notification);
      emitter.emit('show-toast', {
        title: notification.title,
        message: notification.message,
        topic: 'message',
        toastType: 'info',
        toastDuration: 3000,
      });
      break;

    case NotificationEnum.PARTNERSHIP_REQUEST:
      commit('pushNotificationToQueue', notification);
      emitter.emit('show-toast', {
        title: notification.title,
        message: notification.message,
        topic: 'message',
        toastType: 'info',
        toastDuration: 3000,
      });
      break;

    default:
      console.warn('Unknown notification type:', notification.type);
  }
}
