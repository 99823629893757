import axios from "../../axios-auth";

// Storage route path
const routePath = "/notifications";

export default {
  getNotifications({ state, commit }) {
    const userUUID = state.user.uuid;
    axios
      .get(`${routePath}/${userUUID}`)
      .then((res) => {
        commit("storeNotifications", res.data.data);
      })
      .catch((error) => {
        console.log("action error", error);
      });
  },

  markAllNotificationsAsRead({ state, commit }) {
    const userUUID = state.user.uuid;
    axios
      .patch(`${routePath}/markAllRead/${userUUID}`)
      .then(() => {
        commit("markAllNotificationsAsReadLocally");
      })
      .catch((error) => {
        console.log("Failed to mark notifications as read:", error);
      });
  },
};
