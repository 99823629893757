<script setup lang="ts">
import { ref, computed } from "vue";
import AppSubMenu from "./AppSubMenu.vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Initialization
const store = useStore();

const { t } = useI18n();
useLocaleMessages({
  da: () => import('@/locales/da/layout/appMenu.json'),
  en: () => import('@/locales/en/layout/appMenu.json'),
});

const userType = computed(() => {
  if (store.state.user && store.state.user !== null) {
    return store.state.user.userType;
  } else {
    return 'Loading...';
  }
});

// const unreadMessages = computed(() => store.state.communication.messages.receivedMessagesUnread.length);
const unreadMessages = computed(() => store.getters.unreadMessages);
const isGoogleConnected = computed(() => store.getters.isGoogleConnected);
const isMetaConnected = computed(() => {
  const isFacebookAdsConnected = store.getters.isFacebookAdsAccount;
  const isFacebookPagesConnected = store.getters.isFacebookPagesConnected;
  return isFacebookAdsConnected || isFacebookPagesConnected; // Show the Meta menu if either is connected
});

const businessModel = computed(() => {
  const baseModel = [
    {
      label: t('appMenu.business.label'),
      icon: "pi pi-home",
      items: [
        {
          label: t('appMenu.business.dashboard'),
          icon: "pi pi-fw pi-home",
          to: "/",
        },
        {
          label: t('appMenu.business.messages'),
          icon: "pi pi-fw pi-home",
          to: "/messages/inbox",
          unreadMessages: unreadMessages.value,
        },
        {
          label: t('appMenu.business.contentCalendar'),
          icon: "pi pi-fw pi-calendar",
          to: "/contentkalender",
        },
        {
          label: t('appMenu.business.files'),
          icon: "pi pi-fw pi-folder",
          to: "/files",
        },
        {
          label: t('appMenu.business.setup'),
          icon: "pi pi-fw pi-calendar",
          to: "/meta-checklist",
        },
      ],
    },
  ];

  // Add the Google-specific menu item if Google is connected
  if (isGoogleConnected.value) {
    baseModel[0].items.push({
      label: t('appMenu.business.googleAds'),
      icon: "pi pi-fw pi-google",
      to: "/google-integration",
    });
  }

  // Add the Meta-specific menu item if either Facebook Ads or Pages is connected
  if (isMetaConnected.value) {
    baseModel[0].items.push({
      label: t('appMenu.business.meta'),
      icon: "pi pi-fw pi-facebook",
      to: "/meta-integration",
    });
  }

  return baseModel;
});


const consultantModel = computed(() => {
  const baseModel = [
    {
      label: t('appMenu.agency.label'),
      icon: "pi pi-home",
      items: [
        {
          label: t('appMenu.agency.dashboard'),
          icon: "pi pi-fw pi-home",
          to: "/",
          userAccessLevel: "starter",
        },
        {
          label: t('appMenu.agency.messages'),
          icon: "pi pi-fw pi-comments",
          to: "/messages/inbox",
          userAccessLevel: "starter",
        },
        {
          label: t('appMenu.agency.contentCalendar'),
          icon: "pi pi-fw pi-calendar",
          to: "/contentkalender",
          userAccessLevel: "starter",
        },
        {
          label: t('appMenu.agency.files'),
          icon: "pi pi-fw pi-folder",
          to: "/files",
          userAccessLevel: "professionel",
        },
        {
          label: t('appMenu.agency.setup'),
          icon: "pi pi-fw pi-calendar",
          to: "/meta-checklist",
          userAccessLevel: "starter",
        },
      ],
    },
  ];

  // Add the Google-specific menu item if Google is connected
  if (isGoogleConnected.value) {
    baseModel[0].items.push({
      label: t('appMenu.agency.googleAds'),
      icon: "pi pi-fw pi-google",
      to: "/google-ads",
      userAccessLevel: "starter",
    });
  }

  // Add the Meta-specific menu item if either Facebook Ads or Pages is connected
  if (isMetaConnected.value) {
    baseModel[0].items.push({
      label: t('appMenu.agency.meta'),
      icon: "pi pi-fw pi-facebook",
      to: "/meta",
      userAccessLevel: "starter",
    });
  }

  return baseModel;
});
</script>

<template>
  <AppSubMenu v-if="userType == 'business' || userType == 'personal'" :model="businessModel" />
  <AppSubMenu v-if="userType == 'agency'" :model="consultantModel" />
</template>

<style lang="scss" scoped></style>
