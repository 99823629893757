import axios from '../../../axios-auth';

const routePath = '/meta';

export default {
  getAdAccountCampaigns({ commit, getters, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const { accessToken } = getters.getUserMetaCredentials;
      const {
        insightParams: { time_range, time_increment, fields, date_preset },
        selectedTimePeriod,
        lastSelectedAdAccountID,
      } = data;

      console.log('Access token can be found HERE');
      console.log(accessToken);

      const params = {
        selectedTimePeriod: JSON.stringify(selectedTimePeriod),
        access_token: accessToken,
        time_increment,
        fields: String(fields),
        ...(date_preset ? { date_preset } : { time_range }),
      };

      axios
        .get(`${routePath}/getCampaigns`, {
          params: {
            ...params,
            ad_account_id: lastSelectedAdAccountID,
          },
        })
        .then((res) => {
          const response = res.data;

          commit('storeMetaCampaigns', response.campaigns);

          commit('calculateAndStoreTotalSpend', response.campaigns);
          commit('updateAdCampaignClickMetrics', response.campaigns);
          commit('updateAdCampaignCPCMetrics', response.campaigns);

          // Puplulate general metric
          commit('updateImpressionsGeneralMetric', response.generalMetrics);

          // Resolve the promise indicating success
          resolve();
        })
        .catch((error) => {
          console.error('Error fetching ad account campaigns:');
          console.log(error);

          // Reject the promise if there's an error
          reject(error);
        });
    });
  },
};
