export default {
    storebusinessDataOnBehalfOf_UUID(state, businessDataOnBehalfOf_UUID) {
        state.businessDataOnBehalfOf_UUID = businessDataOnBehalfOf_UUID;
    },
    storebusinessDataOnBehalfOf_Email(state, businessDataOnBehalfOf_Email) {
        state.businessDataOnBehalfOf_Email = businessDataOnBehalfOf_Email;
    },
    storebusinessDataOnBehalfOf_Name(state, businessDataOnBehalfOf_Name) {
        state.businessDataOnBehalfOf_Name = businessDataOnBehalfOf_Name;
    },
    removebusinessDataOnBehalfOf_UUID(state) {
        state.businessDataOnBehalfOf_UUID = null;
    },
    removebusinessDataOnBehalfOf_Email(state) {
        state.businessDataOnBehalfOf_Email = null;
    },
    removebusinessDataOnBehalfOf_Name(state) {
        state.businessDataOnBehalfOf_Name = null;
    },
}