import axios from '../../../axios-auth';
import { ActionContext } from 'vuex';
import { State, GoogleState } from '@/types'; // Adjust the import paths and types as needed

// Define types for the Vuex context and mutations
interface Mutations {
  // For Google
  removeGoogleConnection: (state: GoogleState) => void;
}

// Type for the Action Context
type GoogleActionContext = ActionContext<GoogleState, State> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

// Updated Route Path for Google
const routePath = '/google';

export default {
  async disconnectGooglePlatform({ commit, getters }: GoogleActionContext): Promise<void> {
    console.log('Disconnecting Google platform...');

    try {
      // You can use a token if needed, e.g., for user identification
      const googleAccessToken = getters.googleAuthToken;

      // Make the request to your backend to disconnect Google
      const response = await axios.patch<{ success: boolean }>(`${routePath}/disconnect`, {
        access_token: googleAccessToken,
      });

      // If the response indicates success, remove the Google connection from the state
      if (response.data.success) {
        commit('removeGoogleConnection'); // Update the state by removing the Google connection
        console.log('Google platform disconnected successfully');
      }
    } catch (error) {
      console.error('Failed to disconnect Google platform:', error);
      throw error;
    }
  },

  async fetchGoogleAdCampaigns({ commit, getters }: GoogleActionContext, customerId?: string): Promise<void> {
    console.log('Fetching Google campaigns...');

    if (!customerId) {
      console.error('Customer ID is required but was not provided');
      throw new Error('Customer ID is required');
    }

    const selectedTimePeriod = getters.selectedTimePeriod;

    try {
      // Construct the request URL with the customerId query parameter if provided
      const url = `${routePath}/campaigns?customerId=${customerId}&selectedTimePeriod=${encodeURIComponent(JSON.stringify(selectedTimePeriod))}`;

      // Make the GET request to your backend to fetch the campaign data
      const response = await axios.get<{ success: boolean; data: any[] }>(url);

      if (response.data.success) {
        const responsePayload = response.data.data;

        console.log('Google campaigns fetched successfully:', responsePayload);

        // Commit the campaigns to the state
        commit('setGoogleCampaigns', responsePayload.campaigns);
        commit('setGoogleCampaignGeneralMetrics', responsePayload.generalMetrics);
      }
    } catch (error) {
      console.error('Failed to fetch Google campaigns:', error);
      throw error;
    }
  },
};
