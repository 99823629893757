import axios from '../../../../axios-auth'

// Subscriptions route path
const routePath = '/stripe/billing/subscriptions';

export default {
    fetchSubscriptionPlans({ state, commit }) {
        if (state.subscriptionPlans) {
            return;
        }

        axios.get('/payment/subscriptionPlans')
            .then(res => {
                commit('storeSubscriptionPlans', res.data.data)
            })
            .catch(() => {
                console.log("ERROR fetching subscription plans");
            })
    },

    async attachSubscriptionToCustomer({ state }, { paymentMethodId: paymentMethodId, priceId: priceId }) {
        return axios.post(`${routePath}/attachSubscriptionToCustomer`,
            {
                customerId: state.user.subscriptionPlan.stripeCustomerId,
                paymentMethodId: paymentMethodId,
                priceId: priceId
            })
            .then(res => {
                return res
            })
            .catch(error => {
                return error;
            })
    },

    async createSubscription({}, { customerId, priceId }) {
        return axios.post(`${routePath}/createSubscription`,
            {
                customerId,
                priceId
            })
            .then(res => {
                return res
            })
            .catch(error => {
                return error;
            })
    },

    cancleSubscription({ commit }) {
        axios.post(`${routePath}/cancleSubscription`)
            .then(res => {
                commit('storeUser', res.data.data)
            }).catch(error => {
                console.log(error);
            })
    },

    undoCancelSubscription({ commit }) {
        axios.post(`${routePath}/undoCancelSubscription`)
            .then(res => {
                console.log("result");
                console.log(res);
                commit('storeUser', res.data.data)
            }).catch(error => {
                console.log(error);
            })
    }
}