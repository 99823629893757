export function checkForSelectedPartner(email) {
    const businessEmail = localStorage.getItem("businessDataOnBehalfOf_Email");

    let emails;

    if (businessEmail) {
        return emails = {
            email: email,
            businessPartnerEmail: businessEmail,
        }
    } else {
        return emails = {
            email: email,
            businessPartnerEmail: null,
        }
    }
}

export function checkForSelectedPartnerAndReturnEmail(email) {
    const businessEmail = localStorage.getItem("businessDataOnBehalfOf_Email");
    if (businessEmail) {
        return businessEmail
    }
    return email;
}

export function checkForSelectedPartnerAndReturnUUID(uuid) {
    const partnerUUID = localStorage.getItem("businessDataOnBehalfOf_UUID");
    if (partnerUUID) {
        return partnerUUID
    }
    return uuid;
}