<script setup>
import { ref } from 'vue';
import minmarketingTextLogoV2 from '/images/landingPage/minmarketing-text-logo-v2.png';

import { useRouter } from 'vue-router'; 
const router = useRouter();

const minmarketing_text_logo_v2 = ref(minmarketingTextLogoV2);

const navigateToRoot = () => {
  router.push('/');
};
</script>

<template>
    <img style="width: 20rem; height: auto;" :src="minmarketing_text_logo_v2" alt="MinMarketing text logo" @click="navigateToRoot" />
</template>
