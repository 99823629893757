<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

// State variable to control visibility of the sidebar
const showIndicator = computed(() => !!store.getters.businessDataOnBehalfOf_UUID);

// Function to synchronize localStorage with Vuex state
const syncLocalStorageToVuex = () => {
  const storedBusinessData = localStorage.getItem('businessDataOnBehalfOf_UUID');
  if (storedBusinessData) {
    store.commit('businessDataOnBehalfOf_UUID', storedBusinessData);
  }
};

// Listen for `storage` changes made in other tabs/windows
window.addEventListener('storage', (event) => {
  if (event.key === 'businessDataOnBehalfOf') {
    syncLocalStorageToVuex();
  }
});

onMounted(() => {
  syncLocalStorageToVuex();
});

// Watch for changes in the Vuex store and update the local storage accordingly
watch(
  () => store.getters.businessDataOnBehalfOf,
  (newValue) => {
    if (newValue) {
      localStorage.setItem('businessDataOnBehalfOf', newValue);
    } else {
      localStorage.removeItem('businessDataOnBehalfOf');
    }
  }
);
</script>

<template>
  <div v-if="showIndicator" id="partner-access-indicator"
    style="height: 100vh; width: 1rem; background-color: var(--primary-color); position: fixed; top: 0; z-index: 9999;">
  </div>

  <div id="app">
    <router-view />
  </div>
</template>

<style scoped></style>
