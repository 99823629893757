import { useI18n } from 'vue-i18n';
import { watch } from 'vue';

export function useLocaleMessages(localeFiles: Record<string, () => Promise<any>>) {
  const { locale, setLocaleMessage, getLocaleMessage } = useI18n();

  // Function to load locale messages dynamically
  async function loadLocaleMessages(currentLocale: string) {
    try {
      if (localeFiles[currentLocale]) {
        const messages = await localeFiles[currentLocale]();

        // Merge the new messages into the current locale messages
        setLocaleMessage(currentLocale, {
          ...getLocaleMessage(currentLocale),
          ...messages.default,
        });
      }
    } catch (error) {
      console.error(`Error loading locale messages for ${currentLocale}:`, error);
    }
  }

  // Watch for locale changes and load messages dynamically
  watch(locale, (newLocale) => {
    loadLocaleMessages(newLocale || 'en');
  }, { immediate: true });

  return {
    loadLocaleMessages,
  };
}
