export default {
    addBusinessPartnerRequest(state, partnershipRequest) {
        state.user.business_partner_requests.push({
            email: partnershipRequest.email,
            userUUID: partnershipRequest.userUUID,
            status: partnershipRequest.status,
            profileImageUrl: partnershipRequest.profileImageUrl,
        });
    },

    removeBusinessPartnerRequest(state, receivingUserUUID) {
        const index = state.user.business_partner_requests.findIndex((request) => {
            return request.userUUID === receivingUserUUID; // Match with the correct key 'userUUID'
        });
        
        if (index !== -1) {
            console.log('Removing request at index:', index);
            state.user.business_partner_requests.splice(index, 1);
        } else {
            console.log('No request found with the given receivingUserUUID');
        }
    },

    addBusinessPartner(state, business_partner) {
        state.user.business_partners.push(business_partner);
    },

    removeBusinessPartner(state, partnerUUID) {
        state.user.business_partners = state.user.business_partners.filter(
            partner => partner.userUUID !== partnerUUID
          );
        state.businessDataOnBehalfOf_Email = null;
        state.businessDataOnBehalfOf_Name = null;
    },

    businessDataOnBehalfOf_UUID(state, partnerUUID) {
        state.businessDataOnBehalfOf_UUID = partnerUUID;
    },
}
