// Services
import { handleNotification } from '@/service/webSocket/handleNotificationService';
import { handlePartnershipRequest } from '@/service/webSocket/handlePartnershipRequestService';
import { handleMessage } from '@/service/webSocket/handleMessageService';
import { handleMediaConnectionWebSocket } from '@/service/webSocket/handleMediaConnectionWebSocketService';

// Enums
import { WebSocketMessageEnum } from '@/datastructures/enums/websockets/WebSocketMessageTypes';

export default {
  initializeWebSocket({ state, commit }) {
    const ws = new WebSocket(`${import.meta.env.VITE_WEBSOCKET_URL}?token=${localStorage.getItem('token')}`);

    ws.addEventListener('open', () => {
      console.log('WebSocket Connected');
      commit('SET_WS', ws);

      // Send a pong back to the server when you receive a ping
      ws.addEventListener('ping', () => {
        ws.pong('keepalive');
      });

      // Periodically send a ping message
      setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(`ping from ${state.user.uuid}`);
        }
      }, 30000);
    });

    ws.onmessage = function (event) {
      const message = JSON.parse(event.data);

      console.log('WebSocket message:', message);

      switch (message.type) {
        case WebSocketMessageEnum.NOTIFICATION:
          handleNotification(message.data.fullNotification, commit, state);
          break;
        case WebSocketMessageEnum.CHAT_MESSAGE:
          handleMessage(message.data, commit, state);
          break;
        case WebSocketMessageEnum.PARTNERSHIP_REQUEST:
          handlePartnershipRequest(message.data, commit);
          break;
        case WebSocketMessageEnum.SYSTEM_ALERT:
          handleSystemAlert(message.data, commit);
          break;
        case WebSocketMessageEnum.MEDIA_CONNECTION:
          handleMediaConnectionWebSocket(message.data, commit);
          break;
        default:
          console.warn('Unknown WebSocket message type:', message.type);
      }
    };

    ws.addEventListener('message', (event) => {
      const data = JSON.parse(event.data);
      commit('SET_WS_DATA', data);
    });

    ws.addEventListener('close', (event) => {
      console.log('WebSocket closed: ', event);
      commit('SET_WS', null);
    });
  },
};
