import axios from '../../../../axios-auth'

// Payment methods route path
const routePath = '/stripe/payment_methods/payment_methods';

export default {
    async attachPaymentMethod({ }, { customerId, paymentMethodId }) {
        return axios.post(`${routePath}/attachPaymentMethod`,
            {
                customerId,
                paymentMethodId
            })
            .then(res => {
                console.log("Result from attachPaymentMethod");
                console.log(res);
                return res
            })
            .catch(() => {
                console.log("ERROR attaching payment method");
            })
    },
}