<script setup>
import { ref, defineAsyncComponent } from 'vue';
import AppMenuItem from './AppMenuItem.vue';

const SupportModal = defineAsyncComponent(() => import('@/views/modals/SupportModal.vue'));

// Define props for model
const props = defineProps({
    model: {
        type: Array,
        default: null
    }
});

// Subscription related
const isModalVisible = ref(false);

function openModal() {
    isModalVisible.value = true;
}

function closeModal(visible) {
    isModalVisible.value = visible;
}
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in props.model" :key="i">
            <AppMenuItem :item="item" root :index="i" />

            <li class="menu-separator"></li>

            <div class="absolute bottom-0 flex w-full align-items-center justify-content-center mb-3">
                <Button @click="openModal" text class="w-full" label="Support" />
            </div>
        </template>
    </ul>

    <SupportModal :visible="isModalVisible" @update:visible="closeModal" />
</template>
