import axios from '../../../../axios-auth'

// Customers route path
const routePath = '/stripe/core_resources/customers';

export default {
    async updateCustomerDefaultPaymentMethod({ }, { customerId, paymentMethodId }) {
        return axios.post(`${routePath}/updateCustomerDefaultPaymentMethod`,
            {
                customerId,
                paymentMethodId
            })
            .then(res => {
                console.log("Result from attachPaymentMethod");
                console.log(res);
                return res
            })
            .catch(() => {
                console.log("ERROR running updateCustomerDefaultPaymentMethod");
            })
    },
}