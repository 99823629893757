export default {
  removeMultipleMessages(state, messageUUIDs) {
    // Remove messages from sentMessages
    state.communication.messages.sentMessages = state.communication.messages.sentMessages.filter(
      (message) => !messageUUIDs.includes(message.uuid)
    );

    // Remove messages from receivedMessages
    state.communication.messages.receivedMessages = state.communication.messages.receivedMessages.filter(
      (message) => !messageUUIDs.includes(message.uuid)
    );

    // Optionally, remove from receivedMessagesUnread as well
    state.communication.messages.receivedMessagesUnread = state.communication.messages.receivedMessagesUnread.filter(
      (message) => !messageUUIDs.includes(message.uuid)
    );
  },
};
