<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex"; 
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useLocaleMessages } from "@/utils/locales/useLocaleMessages";

// Enums
import { NotificationEnum } from "@/datastructures/enums/notifications/NotificationEnum";

// Store
const store = useStore();
const router = useRouter();

// Translations
const { t } = useI18n();
useLocaleMessages({
  da: () => import("@/locales/da/layout/appNotificationPanel.json"),
  en: () => import("@/locales/en/layout/appNotificationPanel.json"),
});

// Get the notifications from the store
const notifications = computed(() => store.state.notifications);

// Helper to format date
const formatDate = (date: string) => new Date(date).toLocaleString();

// Emits
const emit = defineEmits(['dismiss']);

const handleNotificationClick = (notification: any) => {
  if(notification.routerLink) {
    router.push(notification.routerLink);
  }

  emit('dismiss');
};
</script>

<template>
  <div class="notification-panel">
    <!-- Title -->
    <div class="notification-title">
      <span class="text-xl">{{ t("appNotificationPanel.title") }}</span>
    </div>

    <!-- Notifications List -->
    <ul>
      <li
        v-for="notification in [...notifications].reverse().slice(0, 6)"
        :key="notification._id"
        :class="{ 'unread-notification': !notification.read }"
        @click="handleNotificationClick(notification)"
      >
        <div class="notification-item clickable">
          <h4>{{ notification.title }}</h4>
          <p>{{ notification.message }}</p>
          <small>{{ formatDate(notification.createdAt) }}</small>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.notification-panel {
  position: absolute;
  top: 6rem;
  right: 3rem;
  width: 25rem;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
}

.notification-title {
  padding: 10px;
  padding-left: 1rem;
  background-color: var(--primary);
  border-bottom: 1px solid #eee;
  font-weight: bold;
  border-radius: 10px 10px 0 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
  width: 100%;
}

.notification-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  transition: background-color 0.3s; /* Smooth transition for background color */
}

.clickable {
  cursor: pointer;
}

/* Hover effect for clickable notifications */
.clickable:hover {
  background-color: #dcdcdc; /* Light dark gray on hover */
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item h4 {
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.notification-item p {
  margin: 5px 0;
}

.notification-item small {
  color: gray;
}

/* Style for unread notifications */
.unread-notification {
  background-color: var(--blue-100);
}

.unread-notification .notification-item {
  padding: 10px 15px; /* Ensures text has padding, but background goes full width */
}
</style>
