<script setup>
import { ref, onBeforeMount, computed } from 'vue';
import { loadStripe } from '@stripe/stripe-js';

// Store
import { useStore } from 'vuex';
const store = useStore();

// Properties
const props = defineProps(['selectedMembershipPlan'])

// Computed 
const ws = computed(() => store.state.ws);

// References
// const fullName = ref("Kristofer Mar Einarsson")
// const selectedCountry = ref({ name: 'Denmark', code: 'DK' })
// const address = ref("testvej 123")
// const zip = ref("1234")

const fullName = ref("")
const countries = ref([{ name: 'Denmark', code: 'DK' }]);
const selectedCountry = ref({ name: 'Denmark', code: 'DK' })
const address = ref("")
const zip = ref("")

const isLoading = ref(false);
const paymentError = ref(false);
const paymentErrorMessage = ref("Transaktionsfejl mod betalingssystem, penge er IKKE blevet trukket. Prøv igen om 5 minutter");

// Variables
let stripe = null;
let loading = ref(true);
let elements = null;

let cardNumberElement = null;
let cardExpiryElement = null;
let cardCVCElement = null;

const hasCardHolderNameBeenFocused = ref(false);
const IsCardHolderNameValid = computed(() => fullName.value.length >= 2 && hasCardHolderNameBeenFocused.value);
const hasPaymentAdressBeenFocused = ref(false);
const IsPaymentAddressValid = computed(() => address.value.length >= 2 && hasPaymentAdressBeenFocused.value);
const hasZipNumberBeenFocused = ref(false);
const IsZipNumberValid = computed(() => zip.value.length >= 2 && hasZipNumberBeenFocused.value);

const allFieldsValid = computed(() => {
    return IsCardHolderNameValid.value && IsPaymentAddressValid.value && IsZipNumberValid.value;
});

async function handleSubscription() {
    isLoading.value = true;
    const cardElement = elements.getElement('cardNumber');

    // Create PaymentMethod using card details
    const { paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
    }).catch((error) => {
        console.log("error");
        console.log(error);
        paymentError.value = true;
        isLoading.value = false;
        return;
    })

    // Attach paymentmethod to customer
    await store.dispatch("attachPaymentMethod", {
        customerId: store.state.user.subscriptionPlan.stripeCustomerId,
        paymentMethodId: paymentMethod.id,
    }).catch(() => {
        paymentError.value = true;
        isLoading.value = false;
        return;
    })

    // Update customer using the paymentmethod
    await store.dispatch("updateCustomerDefaultPaymentMethod", {
        customerId: store.state.user.subscriptionPlan.stripeCustomerId,
        paymentMethodId: paymentMethod.id,
    }).catch(() => {
        paymentError.value = true;
        isLoading.value = false;
        return;
    })

    // Create the subscription
    const subscription = await store.dispatch("createSubscription", {
        customerId: store.state.user.subscriptionPlan.stripeCustomerId,
        priceId: props.selectedMembershipPlan.price.stripePriceId,
    }).catch(() => {
        paymentError.value = true;
        isLoading.value = false;
        return;
    })

    // A promise  for the WebSocket message
    const waitForActionRequired = new Promise((resolve) => {
        const listener = (event) => {
            console.log("event");
            console.log(event);
            const data = JSON.parse(event.data);

            console.log("data received from backend");
            console.log(data);
            if (data.type === 'invoice.payment_action_required') {
                console.log("invoice.payment_action_required hit inside of here");
                resolve(); // Resolve the promise when the required action is needed
                ws.value.removeEventListener('message', listener); // Remove this listener
            }
        }
        console.log("Logging above  addEventListener('message', listener");
        ws.value.addEventListener('message', listener);
    });

    console.log("Before waitForActionRequired");

    await waitForActionRequired;

    console.log("After waitForActionRequired");

    const wsData = computed(() => store.state.wsData);

    console.log("wsData");
    console.log(wsData);

    if (wsData.value.client_secret) {
        console.log("wsData.value.client_secret");
        const { error } = await stripe.confirmCardPayment(wsData.value.client_secret);
        if (error) {
            paymentError.value = true;
            paymentErrorMessage.value = ref("Der opstod en fejl mod betalingssystemet. Ring på support tlf 30119322");
            isLoading.value = false;
            return;
        } else {
            console.log("Updating user now");
            // Update user in internal DB
            await store.dispatch("updateUserSubscription",
                {
                    userId: store.state.user._id,
                    subscriptionVisualName: props.selectedMembershipPlan.visualName,
                    subscriptionPlanName: props.selectedMembershipPlan.name,
                    subscriptionPlanColor: props.selectedMembershipPlan.planColor,
                    priceId: props.selectedMembershipPlan.price.stripePriceId,
                    status: 'active',
                    stripeSubscriptionId: subscription.data.data.id,
                })
                .catch(() => {
                    paymentErrorMessage.value = ref("Der opstod en fejl mod betalingssystemet. Ring på support tlf 30119322");
                    isLoading.value = false;
                    return;
                })
        }
    } else {
        paymentError.value = true;
        paymentErrorMessage.value = ref("Der opstod en fejl mod betalingssystemet. Ring på support tlf 30119322");
        isLoading.value = false;
    }
}

const style = {
    base: {
        color: 'black',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '15px',
        '::placeholder': {
            color: '#aab7c4',
        },
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
    },
};

onBeforeMount(async () => {
    stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
    elements = stripe.elements();
    cardNumberElement = elements.create("cardNumber", { style: style });
    cardExpiryElement = elements.create("cardExpiry", { style: style });
    cardCVCElement = elements.create("cardCvc", { style: style });
    cardNumberElement.mount("#card-number");
    cardExpiryElement.mount("#card-expiry");
    cardCVCElement.mount("#card-cvc");
    loading.value = false;
})

</script>

<template>
    <div>
        <h1>Betalings formular</h1>
        <span>Krypteret og sikret igennem stripe</span>
        <InlineMessage v-if="paymentError == true" class="p-3 mt-2" severity="error">{{ paymentErrorMessage }}
        </InlineMessage>
    </div>

    <div>
        <div class="grid mt-1">
            <div class="col-12">
                <!-- TODO: FOR TESTING -->
                <label for="cardHoldersName">
                    Kortholders navn
                </label>
                <InputText class="mt-1 custom-input" placeholder="Henrik henriksen" id="cardHoldersName" v-model="fullName"
                    type="text" @focus="hasCardHolderNameBeenFocused = true" :class="{
                        'invalid': !IsCardHolderNameValid && hasCardHolderNameBeenFocused,
                    }" />
            </div>
        </div>

        <div class="grid">
            <div class="col-12">
                <label>Kort Number</label>
                <div class="mt-1 custom-input">
                    <div id="card-number"></div>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="col-12 md:col-6">
                <label>Kort udløbsdato</label>
                <div class="mt-1 custom-input">
                    <div id="card-expiry"></div>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <label>Kort CVC</label>
                <div class="mt-1 custom-input">
                    <div id="card-cvc"></div>
                </div>
            </div>
        </div>

        <div class="grid">
            <div class="col-12">
                <label>Land</label>
                <br>
                <Dropdown class="mt-1" style="width: 100%;" v-model="selectedCountry" :options="countries"
                    optionLabel="name" placeholder="Select a Country">
                    <template #value="slotProps">
                        <div v-if="slotProps.value" class="flex align-items-center">
                            <img :alt="slotProps.value.label"
                                src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png"
                                :class="`mr-2 flag flag-${slotProps.value.code.toLowerCase()}`" style="width: 18px" />
                            <div>{{ slotProps.value.name }}</div>
                        </div>
                        <span v-else>
                            {{ slotProps.placeholder }}
                        </span>
                    </template>
                    <template #option="slotProps">
                        <div class="flex align-items-center">
                            <img :alt="slotProps.option.label"
                                src="https://primefaces.org/cdn/primevue/images/flag/flag_placeholder.png"
                                :class="`mr-2 flag flag-${slotProps.option.code.toLowerCase()}`" style="width: 18px" />
                            <div>{{ slotProps.option.name }}</div>
                        </div>
                    </template>
                </Dropdown>
            </div>
        </div>

        <div class="grid">
            <div class="col-12 md:col-6">
                <label for="email">
                    Betalingsadresse
                </label>
                <InputText class="mt-1 custom-input" placeholder="Adressevej 123" id="cardHoldersAddress" v-model="address"
                    type="text" @focus="hasPaymentAdressBeenFocused = true"
                    :class="{ 'invalid': !IsPaymentAddressValid && hasPaymentAdressBeenFocused }" style="width: 100%;"
                    required />
            </div>
            <div class="col-12 md:col-6">
                <label for="postalCode">
                    Post nummer
                </label>
                <InputText class="mt-1 custom-input" placeholder="1050" id="cardHoldersZip" v-model="zip" type="text"
                    @focus="hasZipNumberBeenFocused = true"
                    :class="{ 'invalid': !IsZipNumberValid && hasZipNumberBeenFocused }" style="width: 100%;" required />
            </div>
        </div>
        <hr>
        <div class="grid">
            <div class="col-7">{{ props.selectedMembershipPlan.visualName }} abonnement</div>
            <div class="col-5" style="text-align: right; padding-right: 3rem;"><b>{{
                props.selectedMembershipPlan.price.amount }} DKK</b></div>
        </div>
        <div class="grid">
            <div class="col-7">Moms 25%</div>
            <div class="col-5" style="text-align: right; padding-right: 3rem;"><b>{{
                props.selectedMembershipPlan.price.vat_amount }} DKK</b></div>
        </div>
        <hr>
        <div class="grid">
            <div class="col">Totalt beløb</div>
            <div class="col-5" style="text-align: right; padding-right: 3rem;"><b>{{
                props.selectedMembershipPlan.price.unit_amount_decimal }} DKK</b></div>
        </div>

        <Button style="width: 100%;" size="small" label="Betal" @click="handleSubscription" :loading="isLoading"
            :disabled="!allFieldsValid || isLoading" />
    </div>
</template>

<style scoped>
.custom-input {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 12px;
    width: 100%;
}

.custom-input label {
    font-size: 14px;
    margin-bottom: 4px;
}

.invalid {
    border: 1px solid red;
}</style>
