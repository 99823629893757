<script setup>
import { ref, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';
import { useStore } from 'vuex';

// Enums
import { TimePeriodEnum } from '@/datastructures/enums/common/TimePeriodEnum'

const { t } = useI18n();
useLocaleMessages({
    da: () => import('@/locales/da/components/general/settingsModal.json'),
    en: () => import('@/locales/en/components/general/settingsModal.json'),
});

const store = useStore();

const props = defineProps({
    visible: Boolean,
    timePeriodOptions: Array,
});

const TimePeriodOptions = [
    {
        name: "Today",
        value: TimePeriodEnum.DAY,
        days: 7,
        sumDays: 1
    },
    {
        name: "Last 7 Days",
        value: TimePeriodEnum.WEEK,
        days: 14,
        sumDays: 7
    },
    {
        name: "Last 28 Days",
        value: TimePeriodEnum.MONTH,
        days: 56,
        sumDays: 28
    },
    {
        name: "Maximum time",
        value: TimePeriodEnum.MAXIMUM,
        days: null,
        sumDays: null
    }
];

// References
const selectedTimePeriod = ref(null);
const isVisible = ref(props.visible);

// Emits
const emit = defineEmits(['update:visible', 'close-settings']);

// Set the default time period
const defaultTimePeriod = TimePeriodOptions[1]; // Default to 'Total in the Last 7 Days'

// On mounted, initialize the selectedTimePeriod from localStorage or use the default value
onMounted(() => {
    const savedTimePeriod = localStorage.getItem('mm-selectedTimePeriod');
    if (savedTimePeriod) {
        selectedTimePeriod.value = JSON.parse(savedTimePeriod);
    } else {
        selectedTimePeriod.value = defaultTimePeriod;
        localStorage.setItem('mm-selectedTimePeriod', JSON.stringify(defaultTimePeriod));
    }

    // Sync Vuex store with local storage value
    store.commit('setSelectedTimePeriod', selectedTimePeriod.value);
});

watch(() => props.visible, (newVisible) => {
    isVisible.value = newVisible;
});

// Watchers
watch(selectedTimePeriod, (newTimePeriod) => {
    if (newTimePeriod) {
        localStorage.setItem('mm-selectedTimePeriod', JSON.stringify(newTimePeriod));
        store.commit('setSelectedTimePeriod', newTimePeriod);
    }
}, { deep: true });

// Functions
function handleDialogHide() {
    isVisible.value = false;
    emit('update:visible', false);
    emit('close-settings'); // Emit event to let AppTopbar.vue know the modal is being closed
}
</script>

<template>
    <Dialog v-model:visible="isVisible" closeOnEscape dismissableMask :draggable="false" :showHeader="true"
        @hide="handleDialogHide" modal class="backdrop-filter" :style="{ width: '50%', height: 'auto' }">

        <template #header>
            <div class="flex align-items-center text-center">
                <div class="text-2xl ml-4">{{ t('settingsModal.title') }}</div>
            </div>
        </template>

        <template #default>
            <section class="content ml-4">
                <div class="grid mb-4">
                    <div class="col-12 md:col-8">
                        <label class="text-lg font-semibold mb-3 block">{{
                            t('settingsModal.title-timePeriod') }}</label>
                        <Dropdown v-model="selectedTimePeriod" :options="TimePeriodOptions" optionLabel="name"
                            :placeholder="t('settingsModal.dropDownsPlaceholders.selectTimePeriod')" class="w-full" />
                    </div>
                </div>
            </section>
        </template>

        <!-- <template #footer>
            <Button @click="handleDialogHide" size="small" severity="secondary" text
                :label="t('settingsModal.buttons.close')" />
        </template> -->

    </Dialog>
</template>


<style scoped>
.content {
    height: 10rem;
}
</style>
