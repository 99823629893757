export default {
  isGoogleConnected(state: any) {
    return state.user.platformCredentials.google.connected;
  },
  getGoogleAdsCustomers(state: any) {
    return state.user?.platformCredentials?.google?.data?.customers || [];
  },
  getSelectedGoogleAdsCustomer(state: any) {
    return state.user.marketingData.google.selectedCustomer;
  },

  // Google campaigns
  getGoogleCampaignsGeneralMetrics(state: any) {
    return state.user.marketingData.google.generalMetrics;
  },
};
