export default {
    storeFacebookPages(state, data) {
        state.user.marketingData.meta.facebook.pages = data
    },
    resetFacebookPagesAndInstagram(state) {
        state.user.marketingData.meta.facebook.pages = []
        state.user.marketingData.meta.instagram.dailyInsights = []
        state.user.marketingData.meta.instagram.weeklyInsights = []
        state.user.marketingData.meta.instagram.monthlyInsights = []
        state.user.marketingData.meta.instagram.posts = []
    },
    storeSelectedFacebookPage(state, page) {
        state.user.marketingData.meta.facebook.selectedPage = page
    },
    resetSelectedFacebookPage(state) {
        state.user.marketingData.meta.facebook.selectedPage = null
    },
    storeFacebookPosts(state, data) {
        state.user.marketingData.meta.facebook.posts = data
    },
}