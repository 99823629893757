<script setup>
import LandingPageLogo from "@/views/public/components/LandingPageLogo.vue";
import { nextTick, ref, computed, onMounted, onUnmounted } from "vue";
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Load locales
useLocaleMessages({
  da: () => import('@/locales/da/publicTopBar.json'),
  en: () => import('@/locales/en/publicTopBar.json'),
});

// Router
import { useRouter } from "vue-router";
const router = useRouter();

// OnMounted
onMounted(() => {
  window.addEventListener("resize", checkMobileView);
});
onUnmounted(() => {
  window.removeEventListener("resize", checkMobileView);
});

// Refs
const isMobileView = ref(window.innerWidth < 768);

// Computed
const checkMobileView = () => {
  isMobileView.value = window.innerWidth < 768;
};

const navigateToHome = (id) => {
  router.push("/").then(() => {
    nextTick(() => {
      setTimeout(() => smoothScroll(id), 100); // Adjust delay as needed
    });
  });
};

const smoothScroll = async (id) => {
  const element = document.querySelector(id);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
};

const navigateToResources = () => {
  router.push("/resourcer");
};
</script>

<template>
  <div
    id="home"
    class="flex align-items-center relative lg:static pt-6 px-4 mx-0 md:px-7 lg:px-8 lg:pt-6 lg:mx-8"
  >
    <a class="cursor-pointer">
      <LandingPageLogo />
    </a>

    <i
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
      class="pi pi-bars text-4xl cursor-pointer block md:hidden text-700 right-side-icon"
    ></i>

    <div
      class="align-items-center flex-grow-1 hidden md:flex absolute md:static w-full md:px-0 z-3 shadow-2 md:shadow-none"
      :style="{ top: '80px', right: '0%' }"
    >
      <ul
        class="list-none p-3 md:p-0 m-0 ml-auto flex md:align-items-center select-none flex-column md:flex-row cursor-pointer surface-card md:surface-ground"
      >
        <li>
          <a
            @click="navigateToHome('#home')"
            v-ripple
            class="p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>{{ $t('home') }}</span>
          </a>
        </li>
        <li>
          <a
            @click="navigateToHome('#apps')"
            v-ripple
            class="p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>{{ $t('features') }}</span>
          </a>
        </li>
        <li>
          <a
            @click="navigateToHome('#features')"
            v-ripple
            class="p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>{{ $t('insight') }}</span>
          </a>
        </li>
        <li>
          <a
            @click="navigateToHome('#pricing')"
            v-ripple
            class="p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>{{ $t('pricing') }}</span>
          </a>
        </li>
        <li>
          <a
            @click="navigateToResources()"
            class="p-ripple flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>{{ $t('resources') }}</span>
          </a>
        </li>
        <li>
          <Button
            @click="router.push('/auth/signup')"
            outlined
            type="button"
            :label="$t('signup')"
            class="m-0 mt-3 md:mt-0 md:ml-5"
            :class="{ 'w-full': isMobileView }"
            style="width: 100px"
          ></Button>
        </li>
        <li>
          <Button
            @click="router.push('/auth/signin')"
            type="button"
            :label="$t('login')"
            class="m-0 mt-3 md:mt-0 md:ml-5"
            :class="{ 'w-full': isMobileView }"
            style="width: 100px"
          ></Button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.right-side-icon {
  margin-left: auto;
  margin-right: 2rem;
}
</style>
