export default {
  hasInstagramPageLinked(state) {
    if (state.user.platformCredentials.meta.data.pages.data && state.user.platformCredentials.meta.data.pages.data.length > 0) {
      return state.user.platformCredentials.meta.data.pages.data.some((page) => page.instagram_business_account && page.instagram_business_account.id);
    }
    return false;
  },

  instagramDailyInsights: (state) => {
    return state.user.marketingData.meta.instagram.dailyInsights;
  },
  instagramWeeklyInsights: (state) => {
    return state.user.marketingData.meta.instagram.weeklyInsights;
  },
  instagramPosts: (state) => {
    return state.user.marketingData.meta.instagram.posts;
  },
};
