import googleMutations from './google/googleMutations';

export default {
  ...googleMutations,

  // General mutations
  addToTotalClicksOnAd(state: any, payload: number) {
    if (state.marketingData.total.data.totalClicksOnAd === null) {
      state.marketingData.total.data.totalClicksOnAd = 0;
    }
    state.marketingData.total.data.totalClicksOnAd += payload;
  },
};
