export default {
    getMessage: (state) => (uuid) => {
        let message = state.communication.messages.receivedMessages.find(m => m.uuid === uuid);
        if (!message) {
            message = state.communication.messages.sentMessages.find(m => m.uuid === uuid);
        }

        return message;
    },

    unreadMessages: (state) => {
        return state.communication.messages.receivedMessages.filter(message => message.status === 'unread').length;
    }
}