// Child mutations
import metaMutations from './metaMutations';
import adAccountMutations from './adAccountMutations';
import adCampaignMutations from './adCampaignMutations';
import facebookMutations from './facebook/facebookMutations';
import instagramMutations from './instagram/instagramMutations';

export default {
    ...metaMutations,
    ...adAccountMutations,
    ...adCampaignMutations,
    ...facebookMutations,
    ...instagramMutations,
}