import axios from '../../axios-auth';
import { ActionContext } from 'vuex';
import { State, ContactsState, Contact } from '@/types';

interface FileUploadResponse {
  success: boolean;
  imageUrl: string; // The URL of the uploaded image
}

interface Mutations {
  storeContacts: (state: ContactsState, payload: Contact[]) => void;
}

// Action context type for file-related actions
type FileActionContext = ActionContext<ContactsState, State> & {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
};

// Storage route path
const routePath = '/storage/contacts';

export default {
  // Action to upload a contact image
  async uploadContactImage({ state, commit }: FileActionContext, { uuid, formData }: { uuid: string; formData: FormData }): Promise<FileUploadResponse> {
    try {
      formData.append('uuid', uuid);
      const response = await axios.post<FileUploadResponse>(`${routePath}/contact_image`, formData);

      console.log('DEBUGGING: uploadContactImage');
      console.log('response.data', response.data);

      const imageUrl = response.data.data.url;

      // Retrieve the existing contact from the state
      const existingContact = state.contacts.find((contact: Contact) => contact.uuid === uuid);

      if (existingContact) {
        // Create a new object merging existing contact data with the updated image field
        const updatedContact = { ...existingContact, contactImageUrl: imageUrl };

        // Commit the updateContact mutation with the merged contact data
        commit('updateContact', updatedContact);
      }

      return response.data;
    } catch (error) {
      console.error('Error uploading contact image:', error);
      throw error;
    }
  },

  // Action to get the contact file URL
  async getContactFileUrl({ commit }: FileActionContext, filePath: string): Promise<string> {
    console.log('DEBUGGING: getContactFileUrl');
    console.log('filePath', filePath);

    try {
      // Pass the filePath as the fileName query parameter
      const response = await axios.get<{ success: boolean; fileUrl: string }>(`${routePath}/fileUrl`, {
        params: {
          fileName: filePath,
        },
      });

      console.log('DEBUGGING: getContactFileUrl');
      console.log('response', response);

      return response.data.fileUrl;
    } catch (error) {
      console.error('Error getting contact file URL:', error);
      throw error;
    }
  },

  // Action to delete a contact file
  async deleteContactFile({ commit }: FileActionContext, uuid: string): Promise<void> {
    try {
      await axios.delete(`${routePath}/${uuid}/file`);

      // Assuming your mutation removes the contact image URL based on UUID
      commit('removeContactImage', uuid);
    } catch (error) {
      console.error('Error deleting contact file:', error);
      throw error;
    }
  },
};
