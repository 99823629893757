<template>
    <div v-if="toastVisible" class="toaster" :class="toastType" @click="dismiss">
        <div class="toaster-content">
            <i :class="iconClass" class="toaster-icon"></i>
            <div>
                <span class="toaster-title">{{ title }}</span>
                <p class="toaster-message">{{ message }}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import emitter from '@/utils/eventBus'; // Import the event bus

// State and computed values
const toastVisible = ref(false);
const title = ref('');
const message = ref('');
const type = ref('info');
const topic = ref('generic');
const duration = ref(3000);

const toastType = computed(() => `toaster-${type.value}`);
const iconClass = computed(() => {
    switch (topic.value) {
        case 'message':
            return 'pi pi-envelope text-blue-500';
        case 'generic':
        default:
            return 'pi pi-info text-blue-500';
    }
});

// Listen for toast events when the component is mounted
onMounted(() => {
    emitter.on('show-toast', ({ title: toastTitle, message: toastMessage, topic: toastTopic, toastType, toastDuration }) => {
        title.value = toastTitle;
        message.value = toastMessage;
        topic.value = toastTopic || 'generic';
        type.value = toastType || 'info';
        duration.value = toastDuration || 3000;
        toastVisible.value = true;

        // Automatically hide after the specified duration
        setTimeout(() => {
            toastVisible.value = false;
        }, duration.value);
    });
});

// Method to dismiss the toaster early
const dismiss = () => {
    toastVisible.value = false;
};
</script>

<style scoped>
.toaster {
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  z-index: 1000;
  width: 30rem;
}

.toaster-info {
  border-left: 4px solid #3b82f6;
}

.toaster-success {
  border-left: 4px solid #10b981;
}

.toaster-error {
  border-left: 4px solid #ef4444;
}

.toaster-warning {
  border-left: 4px solid #fbbf24;
}

.toaster-content {
  display: flex;
  align-items: flex-start;
  gap: 0.5rem; /* Add space between the icon and the text */
}

.toaster-icon {
  font-size: 1.5rem;
  margin-top: 0.2rem;
  margin-right: 1rem; /* Space between icon and text */
}

.toaster-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.toaster-message {
  margin-top: 0.5rem;
  color: #666;
}
</style>
