import axios from '../../../axios-auth';

const routePath = '/meta';

export default {
  async getAdAccounts({ commit, getters }: any): Promise<void> {
    try {
      const accessToken = getters.getUserMetaCredentials.accessToken;
      const response = await axios.get<{ data: any }>(`${routePath}/getAdaccounts?access_token=${accessToken}`);

      commit('storeFacebookAdAccounts', response.data);
    } catch (error) {
      console.error('Action error', (error as Error).message);
      throw error;
    }
  },

  async disconnectMetaAdAccount({ commit, getters }: any): Promise<void> {
    console.log('Disconnecting Facebook page...');
    try {
      const response = await axios.patch<{ success: boolean }>(`${routePath}/disconnect`, {});

      // If the response indicates success, remove the Google connection from the state
      if (response.data.success) {
        commit('disconnectAdsAccount');
        commit('removeAdAccounts');
        commit('removeSelectedAdAccount');
      }
    } catch (error) {
      console.error('Failed to disconnect Google platform:', error);
      throw error;
    }
  },
};
