export default {
    isAuthenticated(state) {
        return state.token !== null
    },
    loginStatus(state) {
        return state.loginSucess
    },
    signupStatus(state) {
        return state.signupStatus
    },
    getArticles(state) {
        return state.articles;
    },
}