export default {
    storeProfileLogo(state, profileLogo) {
        state.user.profile.logo.name = profileLogo.name ?? state.user.profile.logo.name;
        state.user.profile.logo.path = profileLogo.path ?? state.user.profile.logo.path;
        state.user.profile.logo.url = profileLogo.url ?? state.user.profile.logo.url;
    },

    storeProfileImage(state, profileImage) {
        state.user.profile.image.name = profileImage.name ?? state.user.profile.image.name;
        state.user.profile.image.path = profileImage.path ?? state.user.profile.image.path;
        state.user.profile.image.url = profileImage.url ?? state.user.profile.image.url;
    },

    removeProfileLogo(state) {
        state.user.profile.logo = {
            name: null,
            url: null,
            path: null,
        };
    },

    storeUser(state, userValues) {
        Object.keys(userValues).forEach(key => {
            state.user[key] = userValues[key];
        });
    },

    applyForPartnershipStatus(state, status) {
        state.applyForPartnershipStatus = status
    },

    applyForPartnershipStatusReset(state) {
        state.applyForPartnershipStatus = {
            status: null,
            message: ""
        }
    },
}