export default {
    storeFiles(state, files) {

        console.log('files being stored', files);

        if (state.files.selectedFolder) {
            state.files.filesSorted = files.filter(file => file.fileType === 'file' && file.fullName.includes(state.files.selectedFolder));
        } else {
            state.files.filesSorted = files.filter(file => file.fileType === 'file' && file.fullName.split('/').length - 1 <= 2);
        }
        state.files.files = files.filter(file => file.fileType === 'file');
        this.commit('storeFileTotalSize')
    },

    
    storeFolders(state, files) {
        state.files.folders = files.filter(file => file.fileType === 'folder');
    },


    addFolderToStore(state, folderName) {
        const folderToAdd = {
            fileType: 'folder',
            fullName: state.user.email + '/' + folderName,
            name: folderName,
        }
        state.files.folders.push(folderToAdd)
    },


    removeFolderFromStore(state, folderName) {
        const folderIndex = state.files.folders.findIndex(folder => folder.name === folderName);
        if (folderIndex !== -1) {
            state.files.folders.splice(folderIndex, 1);
        }
        state.files.selectedFolder = 'Hovedfolder'
    },


    removeFileFromStore(state, fileName) {
        state.files.filesSorted = state.files.filesSorted.filter(file => file.fullName !== fileName);
        state.files.files = state.files.files.filter(file => file.fullName !== fileName);
        this.commit('storeFilesCategories', state.files.files)
        this.commit('storeFileTotalSize')
    },

    moveFileToFolder(state, { filePayload, result}) {

        state.files.filesSorted = state.files.filesSorted.filter(file => file.fullName !== filePayload.srcFilename);

        state.files.files.forEach(file => {
            if (file.fullName === filePayload.srcFilename) {
                file.fullName = filePayload.destFilename
            }
        })
    },

    setSelectedFolder(state, folderName) {
        state.files.selectedFolder = folderName
        state.files.filesSorted = state.files.files.filter(file => file.fileType === 'file' && file.fullName.split('/').length - 1 <= 2);
    },

    storeFilesCategories(state, files) {
        const imageIcons = ['pi pi-image'];
        const documentIcons = ['pi pi-file-pdf', 'pi pi-file'];
        const videoIcons = ['pi pi-video'];

        state.files.categorie_size_image = files.filter(file => imageIcons.includes(file.icon)).length
        state.files.categorie_size_documents = files.filter(file => documentIcons.includes(file.icon)).length
        state.files.categorie_size_videos = files.filter(file => videoIcons.includes(file.icon)).length
    },

    storeFileTotalSize(state) {
        let totalBytes = 0;

        state.files.files.forEach(file => {
            if (file.fileSizeNumber !== undefined || file.fileSizeNumber === 0) {
                totalBytes += Number(file.fileSizeNumber)
            }
        });

        const bytesToMb = Number((totalBytes / 1048576));

        state.files.totalFilesSize = bytesToMb
    },

    // Folders
    selectFilesInFolder(state, folderName) {
        state.files.selectedFolder = folderName
        state.files.filesSorted = state.files.files.filter(
            file => file.fileType === 'file' && file.fullName.includes(folderName))
    }
}
