import axios from '../../axios-auth';
import { 
    checkForSelectedPartnerAndReturnUUID,
    checkForSelectedPartnerAndReturnEmail 
} from "@/service/users/userCheck";

export default {
    async getCalendar({ state, commit }) {
        if (!state.token) {
            return Promise.reject('No token available');
        }

        console.log('state.user.uuid', checkForSelectedPartnerAndReturnUUID(state.user.uuid));

        try {
            const res = await axios.get(`/marketingCalendar/${checkForSelectedPartnerAndReturnUUID(state.user.uuid)}`);
            console.log('res.data', res.data);
            commit('storeCalendar', res.data);
            return res.data; // Return the data to allow for awaiting the result
        } catch (error) {
            console.log('action error', error);
            return Promise.reject(error); // Reject the promise with the error
        }
    },

    async addCalendarData({ state, commit }, calendarData) {
        if (!state.token) {
            return Promise.reject('No token available');
        }

        console.log('addCalendarData', calendarData);

        try {
            const res = await axios.post(`/marketingCalendar/${checkForSelectedPartnerAndReturnUUID(state.user.uuid)}`, calendarData);
            commit('storeMarketingCalendar', res.data.marketingCalendar);
            return res.data.marketingCalendar; // Return the data to allow for awaiting the result
        } catch (error) {
            console.log('action error', error);
            return Promise.reject(error); // Reject the promise with the error
        }
    },

    async patchCalendarData({ state, commit }, { eventUUID, updatedEventData }) {    
        if (!state.token) {
            return Promise.reject('No token available');
        }

        try {
            const userUUID = checkForSelectedPartnerAndReturnUUID(state.user.uuid); // Assuming this function returns the UUID
            const res = await axios.patch(`/marketingCalendar/${userUUID}`,{ eventUUID, updatedEventData });
            commit('patchCalendarEvent', res.data.updatedEvent);
            return res.data.marketingCalendar; // Return the data to allow for awaiting the result
        } catch (error) {
            console.log('action error', error);
            return Promise.reject(error);
        }
    },

    async deleteCalendarData({ state, commit }, uuid) {
        if (!state.token) {
            return Promise.reject('No token available');
        }

        try {
            const res = await axios.delete(`/marketingCalendar/${checkForSelectedPartnerAndReturnUUID(state.user.uuid)}?eventUUID=${uuid}`);            
            commit('deleteCalendarEvent', res.data);
            return res.data.marketingCalendar;
        } catch (error) {
            console.log('action error', error);
            return Promise.reject(error);
        }
    }
};
