import { IPlatformCredentials } from '@datastructures/interfaces';

const platformCredentialsState: IPlatformCredentials = {
  meta: {
    connected: false,
    data: {
      accessToken: null,
      dataAccessExpirationTime: null,
      expiresIn: null,
      graphDomain: null,
      signedRequest: null,
      userID: null,
      adAccounts: {
        connected: false,
        data: [],
      },
      pages: {
        connected: false,
        data: [],
      },
    },
  },
  google: {
    connected: false,
    data: {
      customers: [],
    },
  },
  linkedin: {
    connected: false,
    data: {},
  },
};

export default platformCredentialsState;