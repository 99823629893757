<script setup>
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Load locales
useLocaleMessages({
  da: () => import('@/locales/da/landingPage.json'),
  en: () => import('@/locales/en/landingPage.json'),
});
</script>

<template>
    <div class="text-900 font-bold text-6xl mb-4 text-center">{{ $t('insight-title') }} </div>
  
    <span class="text-700 block text-xl mb-8 text-center line-height-3">{{ $t('insight-subtitle') }}</span>
  
    <div class="grid mt-8">
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icons8-puzzle-30.png`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('simplicity-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('simplicity-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icon-productivity.svg`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('productivity-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('productivity-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icon-accessibility.svg`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('accessibility-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('accessibility-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icon-components.svg`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('marketing-tools-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('marketing-tools-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icon-support.svg`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('support-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('support-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icon-mobile.svg`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('mobile-adaptation-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('mobile-adaptation-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icon-theme.svg`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('customization-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('customization-description') }}</p>
        </div>
      </div>
  
      <div class="col-12 md:col-6 xl:col-3 flex justify-content-center p-3">
        <div class="box p-4 w-full surface-card surface-border border-1 border-round">
          <img :src="`/demo/images/landing/icons8-security-shield-30.png`" alt="components icon" class="block mb-3" />
          <span class="text-900 block font-semibold mb-3 text-lg">{{ $t('security-title') }}</span>
          <p class="m-0 text-secondary text-700">{{ $t('security-description') }}</p>
        </div>
      </div>
    </div>
</template>