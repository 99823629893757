<script setup>
import { computed } from 'vue';
import AppMenu from "./AppMenu.vue";
import { useLayout } from '@/layout/composables/layout';

// Layout
const { layoutConfig } = useLayout();

// Store
import { useStore } from 'vuex';
const store = useStore();

// Router
import { useRouter } from 'vue-router';
const router = useRouter();

// Computed 
const logoUrl = computed(() => {
  const darkOrDimThemeLogoUrl = '/images/MinMarketing-cover-dark-540x180.png';
  const lightThemeLogoUrl = '/images/MinMarketing-cover-light-540x180.png';
  return (layoutConfig.colorScheme.value === 'dark' || layoutConfig.colorScheme.value === 'dim')
    ? darkOrDimThemeLogoUrl
    : lightThemeLogoUrl;
});

const { layoutState } = useLayout();

let timeout = null;

const onMouseEnter = () => {
  if (!layoutState.anchored.value) {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    layoutState.sidebarActive.value = true;
  }
};

const onMouseLeave = () => {
  if (!layoutState.anchored.value) {
    if (!timeout) {
      timeout = setTimeout(
        () => (layoutState.sidebarActive.value = false),
        300
      );
    }
  }
};

const anchor = () => {
  layoutState.anchored.value = !layoutState.anchored.value;
};
</script>

<template>
  <div class="layout-sidebar" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="sidebar-header">
      <div class="sidebar-image-container">
        <img @click="router.push('/')" :style="{ cursor: 'pointer' }" :src="logoUrl" alt="Business Image" />
      </div>
    </div>
    <div class="layout-menu-container">
      <AppMenu />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-image-container img {
  max-width: 100%;
  max-height: 180px;
  height: auto;
}
</style>
