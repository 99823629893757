export default {
  storeNotifications(state, notifications) {
    state.notifications = notifications;
  },
  pushNotificationToQueue(state, notification) {
    state.notifications.push(notification);
  },
  markAllNotificationsAsReadLocally(state) {
    state.notifications = state.notifications.map((notification) => ({
      ...notification,
      read: true,
    }));
  },
};
