export default {
    authUser(state, userData) {
        state.token = userData.token
    },
    loginReset(state) {
        state.loginSucess = null
    },
    loginSucess(state) {
        state.loginSucess = true
    },
    loginStatus(state) {
        state.loginSucess = false
    },
    signupStatus(state, status) {
        state.signupStatus = status
    },
    signupStatusReset(state) {
        state.signupStatus = {
            status: null,
            message: null
        }
    },

    setMetaAuthenticated(state, value) {
        state.isMetaAuthenticated = value;
    },

    clearSession() {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('MinMarketing_email');
        localStorage.removeItem('businessDataOnBehalfOf_UUID');
        localStorage.removeItem('businessDataOnBehalfOf_Email');
        localStorage.removeItem('businessDataOnBehalfOf_Name');
        
        localStorage.removeItem('meta-selectedPage');
        localStorage.removeItem('meta-selectedAdAccount');

        localStorage.removeItem('selectedAdAccount');
        localStorage.removeItem('mm-selectedTimePeriod');
        sessionStorage.clear();
    },
}