<script setup>
import { computed } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useLocaleMessages } from '@/utils/locales/useLocaleMessages';

// Load locales
useLocaleMessages({
  da: () => import('@/locales/da/landingPage.json'),
  en: () => import('@/locales/en/landingPage.json'),
});

const { layoutConfig } = useLayout();

const darkMode = computed(() => {
    return layoutConfig.colorScheme.value !== 'light';
});

</script>


<template>
    <!-- Features Title and Subtitle -->
    <div class="text-900 font-bold text-6xl mb-4 text-center">
      {{ $t('features-title') }}
    </div>
    <span class="text-700 block text-xl mb-8 text-center line-height-3">
      {{ $t('features-subtitle') }}
    </span>
  
    <!-- Features Section -->
    <div class="flex flex-column lg:flex-row align-items-center justify-content-between mt-8 gap-8">
      <!-- Tracking Feature -->
      <div class="flex flex-column align-items-center">
        <img
          :src="`/images/landingPage/kontrolpanel-graph.png`"
          alt="Tracking"
          class="images border-round surface-border shadow-2 animation-duration-500 scalein origin-top"
        />
        <span class="block text-900 text-lg font-semibold mt-4">
          {{ $t('tracking-title') }}
        </span>
        <div class="mt-4">
          <span class="text-700 block text-center line-height-3">
            {{ $t('tracking-subtitle') }}
          </span>
        </div>
      </div>
  
      <!-- Content Calendar Feature -->
      <div class="flex flex-column align-items-center">
        <img
          :src="`/images/landingPage/content-kalender.png`"
          alt="Content Calendar"
          class="images border-round surface-border shadow-2 animation-duration-500 scalein origin-top"
        />
        <span class="block text-900 text-lg font-semibold mt-4">
          {{ $t('content-calendar-title') }}
        </span>
        <div class="mt-4">
          <span class="text-700 block text-center line-height-3">
            {{ $t('content-calendar-subtitle') }}
          </span>
        </div>
      </div>
  
      <!-- Status Messages Feature -->
      <div class="flex flex-column align-items-center">
        <img
          :src="`/images/landingPage/status-messages.png`"
          alt="Status Messages"
          class="images border-round surface-border shadow-2 animation-duration-500 scalein origin-top"
        />
        <span class="block text-900 text-lg font-semibold mt-4">
          {{ $t('status-messages-title') }}
        </span>
        <div class="mt-4">
          <span class="text-700 block text-center line-height-3">
            {{ $t('status-messages-subtitle') }}
          </span>
        </div>
      </div>
    </div>
  </template>

<style scoped>
.description {
    height: 10rem;
}

.images {
    height: 20rem;
    width: 25rem;
}
</style>