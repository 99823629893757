export default {
  getSelectedFacebookPage: (state) => {
    return state.user.marketingData.meta.facebook.selectedPage;
  },
  getFacebookPages: (state) => {
    return state.user.marketingData.meta.facebook.pages;
  },
  facebookPosts: (state) => {
    return state.user.marketingData.meta.facebook.posts;
  },
};
