<script setup>
import { onMounted } from 'vue';

// Components
import StripePayment from './StripePayment.vue'

// Properties
const props = defineProps(['selectedMembershipPlan'])

onMounted(() => {
    console.log('stripe mounted')
    console.log(props.selectedMembershipPlan)
});

</script>

<template>
    <div class="pt-6 pb-2 md:px-6 lg:px-8">
        <div class="grid">
            <div class="col-12 md:col-5">
                <div class="card">
                    <div class="grid">
                        <div class="col" style="display: flex; flex-direction: column; justify-content: center;">
                            <h3 style="margin: 0; padding-bottom: 1rem;">{{ props.selectedMembershipPlan.visualName }}
                            </h3>
                            <span class="text-700 text-xl">Plan</span>
                        </div>
                        <div class="col" style="display: flex; justify-content: flex-end; align-items: center;">
                            <svg x="0px" y="0px" width="80" height="50" viewBox="0,0,256,240"
                                style="fill:#000000; margin-left: auto; padding-right: 4px;">
                                <g :fill="props.selectedMembershipPlan.planColor" fill-rule="nonzero" stroke="none"
                                    stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10"
                                    stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none"
                                    font-size="none" text-anchor="none" style="mix-blend-mode: normal">
                                    <g transform="scale(5.40,5.40)">
                                        <path
                                            d="M8.98828,11.25c-0.19531,0.00311 -0.3817,0.08229 -0.51953,0.2207l-7.99805,8c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.46875,-7.46875l6.93945,6.93945l-7.46875,7.4707c-0.29278,0.2929 -0.29278,0.76765 0,1.06055l8,7.99805c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-7.46875,-7.46875l6.93945,-6.93945l7.4707,7.46875c0.2929,0.29278 0.76765,0.29278 1.06055,0l7.99805,-8c0.29278,-0.2929 0.29278,-0.76765 0,-1.06055l-8,-7.99805c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.2929,-0.29278 -0.76765,-0.29278 -1.06055,0l-7.46875,7.46875l-7.4707,-7.46875c-0.14309,-0.14367 -0.33826,-0.22329 -0.54102,-0.2207zM9,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM41,13.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945zM25,29.06055l6.93945,6.93945l-6.93945,6.93945l-6.93945,-6.93945z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>

                <div v-for="benefit in props.selectedMembershipPlan.benefits" class="flex card-container overflow-hidden">
                    <div class=" flex align-items-center justify-content-center px-5 py-3">
                        <i class="pi pi-check text-green-500 mr-4"></i>
                        <p>{{ benefit }}</p>
                    </div>
                </div>
                <div class="flex  overflow-hidden">
                    <div class=" flex align-items-center justify-content-center px-5 py-3">
                        <p>Secure payment from stripe</p>
                    </div>
                </div>

            </div>
            <div class="col-2">
                <div class="vertical-line"></div>
            </div>

            <!-- Payment information -->
            <div class="col-12 md:col-5">
                <StripePayment :selectedMembershipPlan="props.selectedMembershipPlan">
                </StripePayment>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.vertical-line {
    width: 1px;
    /* Width of the vertical line */
    height: 100%;
    /* Adjust the height as needed */
    background-color: #ccc;
    /* Color of the line, adjust as needed */
    margin: 0 auto;
    /* Center the line horizontally, remove if not needed */
}
</style>
