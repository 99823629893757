export function calculateTotalSpend(campaigns, selectedTimePeriod) {
    let totalSpend = 0;

    if (selectedTimePeriod.value === 'maximum') {
        campaigns.forEach(campaign => {
            if (campaign.insights && campaign.insights.data) {
                const sumForCampaign = campaign.insights.data.reduce(
                    (acc, curr) => acc + parseFloat(curr.spend),
                    0
                );
                totalSpend += sumForCampaign;
            }
        });
    } else {
        campaigns.forEach(campaign => {
            if (campaign.insights && campaign.insights.data) {
                const daysToSum = selectedTimePeriod.sumDays;
                const relevantData = campaign.insights.data.slice(-daysToSum);
                const sumForCampaign = relevantData.reduce(
                    (acc, curr) => acc + parseFloat(curr.spend),
                    0
                );
                totalSpend += sumForCampaign;
            }
        });
    }

    return totalSpend;
}

export function calculatePriorTotalSpend(campaigns, selectedTimePeriod) {
    let priorTotalSpend = 0;

    if (selectedTimePeriod.value === 'maximum') {
        priorTotalSpend = 0;
    } else {
        campaigns.forEach(campaign => {
            if (campaign.insights && campaign.insights.data) {
                const { data } = campaign.insights;
                let daysToSum = 0;
                let daysToSkip = 0;

                if (data.length === 7) {
                    daysToSum = 1;
                    daysToSkip = 1;
                } else if (data.length === 14) {
                    daysToSum = 7;
                    daysToSkip = 7;
                } else if (data.length === 56) {
                    daysToSum = 28;
                    daysToSkip = 28;
                }

                const relevantData = data.slice(-daysToSum - daysToSkip, -daysToSkip);
                const sumForPeriod = relevantData.reduce((acc, curr) => acc + parseFloat(curr.spend), 0);
                priorTotalSpend += sumForPeriod;
            }
        });
    }

    return priorTotalSpend;
}

export function calculateTotalSpendByDate(campaigns) {
    let spendByDate = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.data) {
            campaign.insights.data.forEach(entry => {
                spendByDate[entry.date_start] = (spendByDate[entry.date_start] || 0) + parseFloat(entry.spend);
            });
        }
    });

    return Object.keys(spendByDate).map(date => ({
        date: date,
        spend: spendByDate[date].toFixed(2)
    })).sort((a, b) => new Date(a.date) - new Date(b.date));
}

export function calculateTotalSpendInWeeks(campaigns) {
    let spendByWeek = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInWeeks) {
            campaign.insights.dataInWeeks.forEach(entry => {
                spendByWeek[entry.date_start] = (spendByWeek[entry.date_start] || 0) + parseFloat(entry.spend);
            });
        }
    });

    return Object.keys(spendByWeek).map(week => ({
        week: week,
        spend: spendByWeek[week].toFixed(2)
    })).sort((a, b) => a.week.localeCompare(b.week));
}

export function calculateTotalSpendInMonths(campaigns) {
    let spendByMonth = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInMonths) {
            campaign.insights.dataInMonths.forEach(entry => {
                spendByMonth[entry.date_start] = (spendByMonth[entry.date_start] || 0) + parseFloat(entry.spend);
            });
        }
    });

    return Object.keys(spendByMonth).map(month => ({
        month: month,
        spend: spendByMonth[month].toFixed(2)
    })).sort((a, b) => a.month.localeCompare(b.month));
}

export function calculateTotalSpendInYears(campaigns) {
    let spendByYear = {};

    campaigns.forEach(campaign => {
        if (campaign.insights && campaign.insights.dataInYears) {
            campaign.insights.dataInYears.forEach(entry => {
                spendByYear[entry.date_start] = (spendByYear[entry.date_start] || 0) + parseFloat(entry.spend);
            });
        }
    });

    return Object.keys(spendByYear).map(year => ({
        year: year,
        spend: spendByYear[year].toFixed(2)
    })).sort((a, b) => parseInt(a.year) - parseInt(b.year));
}
